
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { Action } from 'vuex-class';

import { NotificationPayload } from '@/store/notifications';

@Component({
  name: 'global-notifications',
})
export default class GlobalNotifications extends Vue {
  @Action('showSuccess') showSuccess!: (val: string | NotificationPayload) => {};
  @Action('showError') showError!: (val: string | NotificationPayload) => {};
  @Action('showWarning') showWarning!: (val: string | NotificationPayload) => {};
}
