import ContactTypesApiService from '@/services/api/ContactTypesApiService';
import ContactTypeNew from '@/entities/ContactTypeNew';

interface ContactTypesCollection {
  count: number,
  types: ContactTypeNew[],
}

class ContactTypeService {
  async getContactTypes(params?: any): Promise<ContactTypesCollection> {
    const response = await ContactTypesApiService.getContactTypes(params);

    return {
      count: parseInt(response.count, 10),
      types: response.types.map((contactType) => new ContactTypeNew(contactType)),
    };
  }

  async addContactType(payload: any): Promise<ContactTypeNew> {
    const response = await ContactTypesApiService.addContactType(payload);
    return new ContactTypeNew(response);
  }

  async deleteContactType(id: string): Promise<void> {
    await ContactTypesApiService.deleteContactType(id);
  }
}

export { ContactTypeService as default };
