import _ from 'lodash';
import IContactType from '@/entities/IContactType';
import IServiceContactType from '@/services/api/models/IServiceContactType';

export default class ContactTypeNew implements IContactType {
  contactTypeId: string;
  name: string;
  entity: string;

  constructor(serviceObj: IServiceContactType) {
    this.contactTypeId = serviceObj.contact_type_id;
    this.name = serviceObj.name;
    this.entity = serviceObj.entity;
  }
}
