


















































































import { debounce } from 'lodash';

import {
  Component, Prop, Emit, Mixins,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import Agency from '@/entities/Agency';

import AgencyService from '@/services/agencies';

import UserPermissions from '@/mixins/UserPermissions.vue';

@Component({
  name: 'agencies-list',
})
export default class AgenciesList extends Mixins(UserPermissions) {
  @Prop({
    type: Number,
    default: 1,
  }) private readonly fontSize!: number;

  private parcelConfigurationLimit: number = 10000;

  private agencySearch: string = '';

  private totalAgencies = 0;
  private isLoading = true;
  private currentOptions: any = {};
  private currentSearch = '';
  private currentDebounce: Function = null;

  private columns: DataTableHeader[] = [
    { text: 'Agency Code', value: 'capAgency' },
    { text: 'Name', value: 'name' },
    { text: 'State', value: 'address.value.state' },
    { text: 'County', value: 'address.value.county' },
    { text: 'Collector Phone', value: 'collectorPhone', sortable: false },
    { text: 'Parcel Configurations', value: 'parcelConfigurations', sortable: false },
    { text: 'ICL/OCL', value: 'countyLines', sortable: false },
    { text: 'Parcel Coding Notes', value: 'parcelCoding', sortable: false },
    { text: 'Tax Offices', value: 'relatedAgencies', sortable: false },
  ];

  private agencies: Agency[] = [];

  private searchCriteria: any[] = [
    {
      text: 'All',
      value: 'all',
    },
    {
      text: 'Agency Code',
      value: 'agency_code',
    },
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'State',
      value: 'state',
    },
    {
      text: 'County',
      value: 'county',
    },
    {
      text: 'Collector Phone',
      value: 'collector_phone',
    },
    {
      text: 'Assessor Phone',
      value: 'assessor_phone',
    },
    {
      text: 'Parcel Configurations',
      value: 'parcel_configurations',
    },
    {
      text: 'Parcel Coding Notes',
      value: 'parcel_coding',
    },
  ];

  private currentSearchBy: any = {
    text: 'All',
    value: 'all',
  };

  async created() {
    this.totalAgencies = 0;
  }

  async updateOptions(options: any) {
    this.isLoading = true
    this.currentOptions = options;
    this.getAgencies()
  }

  async searchFieldUpdated(search: string) {
    let shouldGetLoans = true;
    if (this.currentSearch.length === 0 && search.length === 1) {
      shouldGetLoans = false;
    }
    if (this.currentSearch.length === 1 && search.length === 0) {
      shouldGetLoans = false;
    }
    this.currentSearch = search;
    if (shouldGetLoans) {
      this.currentOptions.page = 1;
      this.getAgencies();
    }
  }

  async updateSearchBy() {
    this.currentOptions.page = 1;
    this.getAgencies();
  }

  async getAgencies() {
    this.isLoading = true;
    if (!this.currentDebounce) {
      this.currentDebounce = debounce(this.searchForAgency, 500);
    }

    this.currentDebounce();
  }

  async searchForAgency() {
    const params: any = {
      limit: this.currentOptions.itemsPerPage,
      offset: (this.currentOptions.page - 1) * this.currentOptions.itemsPerPage,
      sort_by: 'agencyCode',
      order_by: 'asc',
      includeConfigurations: true,
      include_related_agencies: true,
    };

    if (this.currentSearch && this.currentSearch.length > 1) {
      params.search_field = this.currentSearchBy.value;
      params.search_value = this.currentSearch;
    }

    if (this.currentOptions.sortBy.length > 0) {
      [params.sort_by] = this.currentOptions.sortBy;
      params.order_by = this.currentOptions.sortDesc[0] ? 'desc' : 'asc';
    }

    try {
      const agencySummary = await (new AgencyService()).getAllAgencies(params);
      this.agencies = agencySummary.results;
      this.agencies.forEach((agency) => {
        const assessor = agency.contacts.find((a) => a.type === 'Assessor') || {};
        agency.assessorPhone = assessor.phone;
        const collector = agency.contacts.find((a) => a.type === 'Collector') || {};
        agency.collectorPhone = collector.phone;
      })
      this.totalAgencies = agencySummary.count;
    } catch (e) {
      console.log(e);
      this.agencies = [];
    }
    this.isLoading = false;
  }

  @Emit('select')
  handleRowClick(agency: Agency) {
    return agency;
  }
}
