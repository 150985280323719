import Axios, { AxiosResponse } from 'axios';

import IServiceContactType from '@/services/api/models/IServiceContactType';

interface ServicesResponse {
  count: any,
  types: IServiceContactType[],
}

export default {
  async getContactTypes(params: any): Promise<ServicesResponse> {
    const response = await Axios.get<ServicesResponse>('/contact-types', {
      params,
    });

    return response.data;
  },

  async addContactType(payload: any): Promise<IServiceContactType> {
    const response = await Axios.post<IServiceContactType, AxiosResponse<IServiceContactType>>('/contact-types', payload);
    return response.data;
  },

  async deleteContactType(id: string): Promise<void> {
    await Axios.delete<void, AxiosResponse<void>>(`/contact-types/${id}`);
  },
};
