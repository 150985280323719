





































































































































































































































































































































































































































































































































































































































































































































































































import {
  Component, Prop, Emit, Mixins, Watch, ProvideReactive,
} from 'vue-property-decorator';
import { at, remove, sortBy } from 'lodash';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';

import Loan from '@/entities/Loan';
import EscrowType from '@/entities/EscrowType';
import ParcelList from '@/views/loans/ParcelList.vue';
import Agency from '@/entities/Agency';
import Parcel from '@/entities/Parcel';
import IFile from '@/entities/IFile';

import LoanService from '@/services/loans';

import VerifiedCheckbox from '@/components/VerifiedCheckbox.vue';
import EntityHistory from '@/views/history/EntityHistory.vue';

import { fileSizeValidator } from '@/validations/vuetify';
import ValidationErrors from '@/mixins/ValidationErrors.vue';
import PreventDirtyLeave from '@/mixins/PreventDirtyLeave.vue';
import UserPermissions from '@/mixins/UserPermissions.vue';
import GlobalNotifications from '@/mixins/GlobalNotifications.vue';

import vuelidateToPatch, {
  JsonPatchPayload, customAddressConverter, simpleVerifiedConverter, JsonPatchEntry, JsonPatchOperator, currencyFieldConverter, falsyNullConverter,
} from '@/helpers/vuelidateToPatch';
import {
  usZipCode, fullDate, shortDate, stateTerritoryAbbr,
} from '@/validations';
import Verified from '@/entities/Verified';

const validations: any = {
  loan: {
    loanId: {},
    loanNumber: {},
    lenderNumber: {},
    lenderId: {},
    loanType: {},
    notes: {},
    contractId: {},
    idFlag: {},
    cvtNumber: {},
    purposeCode: {},
    classCode: {},
    borrowerName: {},
    borrowerName2: {},
    companyName: {},
    problem: {
      verified: {},
    },
    loanLocationType: {},
    branchNumber: {},
    billingNotes: {},
    dateAdded: { fullDate },
    dateReceived: { fullDate },
    loanDateInactive: {},
    loanAddedBy: {},
    loanRemovedBy: {},
    loanIdTag: {},
    active: {},

    parcels: {
      $each: {
        parcelId: {},
        loanId: {},
        loanNumber: {},
        lenderNumber: {},
        parcelNumber: { },

        address: {
          value: {
            address1: {},
            address2: {},
            city: {},
            state: { stateTerritoryAbbr },
            zipCode: { usZipCode },
            county: {},
            lot: {},
            unit: {},
            block: {},
            building: {},
          },
          verified: {},
        },

        mailingAddress: {
          value: {
            address1: {},
            address2: {},
            city: {},
            state: { stateTerritoryAbbr },
            zipCode: { usZipCode },
            county: {},
          },
          verified: {},
        },

        legal: {},
        deleted: {},

        escrowDateDelqSearched: {},
        escrowDelqSearchNotes: {},
        parcelType: {},
        countyLines: {},
        idTag: {},
        sequenceNumber: {},
        parcelNotes: {},
        problem: {
          value: {},
          verified: {},
        },
        activePrincipalBalance: {},
        typeDescription: {},
        collected: {},
        taxLastPaidAmount: {},
        taxPeriodPaid: {},
        taxPaidDate: { fullDate },
        maturityDate: { fullDate },
        whenBilled: {},
        billedReport: {},
        billNumber: {},
        lenderNotes: {},
        legalNotes: {},
        altParcelNumber: {},
        originalNoteDate: { fullDate },

        agencies: {
          $each: {
            escrowHistory: {
              $each: {
                year: {},
                term: {},
                dueDate: { shortDate },
                reportedDate: { fullDate },
                amountPaid: {},
                amountReported: {},
                zeroVerified: {},
                zeroVerifiedReason: {},
                reportedBy: {},
                reportNotes: {},
                recentCorrection: {},
                batchNumber: {},
              },
            },
            nonEscrowHistory: {
              $each: {
                year: {},
                base: {},
                amountDue: {},
                status: {},
                notes: {},
                priorYearStatus: {},
                dueDate: { fullDate },
                reportedDate: { fullDate },
                statusUpdatedOn: { shortDate },
              },
            },
            parcelAgencyVerified: {},
            sequenceNumber: {},
          },
        },

        dateDeleted: {},
        dateInactive: {},
        dateCoded: {},
        dateAdded: {},
        hasMatchingConfiguration: {},

        verified: {},
        active: {},
      },
    },
  },
};

@Component({
  name: 'loan-detail',
  validations,
  components: {
    ParcelList,
    VerifiedCheckbox,
    EntityHistory,
  },
  mixins: [validationMixin],
})
export default class LoansDetail extends Mixins(UserPermissions, ValidationErrors, PreventDirtyLeave, GlobalNotifications) {
  @Prop({
    type: Number,
    default: 1,
  }) private readonly fontSize!: any;

  @Prop({
    type: String,
    default: null,
  }) private readonly loanId!: string;

  @Prop({
    type: String,
  }) private readonly view!: string;

  @ProvideReactive('font') private font: number = 1.0;

  @Watch('fontSize', { immediate: true })
  onFontSizeChanged(val: number) {
    this.font = val;
  }

  // Snackbars
  private patchError: any = null;

  private editMode: boolean = false;
  private loading: boolean = false;
  private updating: boolean = false;
  private confirmLoanActive: boolean = false;

  private expandedLoanNotes: boolean = false;
  private expandedBillingNotes: boolean = false;

  private selectedAgency: Agency = null;

  private service: LoanService = new LoanService();
  private loan: Loan = null;

  private addedAgencies: { [index: number]: string[] } = {};
  private removedAgencies: { [index: number]: string[] } = {};
  private addedEscrows: { [index: number]: Map<string, any[]> } = {};
  private removedEscrows: { [index: number]: Map<string, any[]> } = {};
  private addedNonEscrows: { [index: number]: Map<string, any[]> } = {};
  private removedNonEscrows: { [index: number]: Map<string, any[]> } = {};

  private files: File[] = [];
  private fileRules: Function[] = [
    fileSizeValidator(15000000, 'File size limit is 15 MB'),
  ];
  private fileValid: boolean = false;

  private escrowTypeOptions = Object.keys(EscrowType).filter((k) => typeof EscrowType[k as keyof typeof EscrowType] === 'string').map((k) => ({
    text: capitalCase(k),
    value: EscrowType[k as keyof typeof EscrowType],
  })).filter((option) => option.value !== '');

  private confirmingDeletion = false;
  private showingHistory = false;
  private setActiveOnParcelsActive = false;
  private setInactiveOnParcelsInactive = false;

  // Computed
  get currencyOptions() {
    return {
      locale: 'en',
      currency: 'USD',
      precision: 2,
    };
  }

  get dirtyFields(): string[] {
    return Object.keys(this.$v.loan.$params)
      .filter((fieldName) => this.$v.loan[fieldName].$anyDirty);
  }

  get invalidFields(): string[] {
    return Object.keys(this.$v.loan.$params)
      .filter((fieldName) => this.$v.loan[fieldName].$invalid);
  }

  get nestedInvalidFields(): string[] {
    const leafParams = this.$v.loan.$flattenParams();
    const invalidFields: string[] = [];

    leafParams.forEach((param) => {
      const path = param.path.join('.');
      if (at(this.$v.loan, path)[0].$invalid) {
        invalidFields.push(path);
      }
    });

    return invalidFields;
  }

  get billingReports(): string {
    const billedReportsArray = this.loan.parcels.filter((parcel) => parcel.billedReport).reduce((finalArray: string[], parcel: Parcel) => {
      finalArray.push(parcel.billedReport);
      return finalArray;
    }, []);

    return billedReportsArray.join(', ');
  }

  // Hooks
  async created() {
    this.expandedLoanNotes = false;
    this.expandedBillingNotes = false;
    this.editMode = this.$route.query.editMode === 'true';
    await this.getLoan();
  }

  async getLoan(quiet: boolean = false, field: keyof Loan = null) {
    try {
      this.loading = !quiet;
      this.service.getLoan(this.loanId || this.$route.params.id)
        .then((loan) => {
          if (field) {
            (this.loan as any)[field] = loan[field];
            return;
          }

          if (this.isLenderUser && loan.dateAdded) {
            [, , loan.dateAdded] = loan.dateAdded.split('/');
          }

          this.loan = loan;
          this.handleLoanSorting();
          this.selectedAgency = this.loan.agencies ? this.loan.agencies[0] : null;
          this.loading = false;
          this.$nextTick(() => {
            this.$v.$reset();
          });
        });
    } catch (e) {
      console.log(e);
    }
  }

  // sorts the parcels and agencies on those parcels
  // also finds the closest due date for each loan
  handleLoanSorting() {
    const agencyCount: any = {};
    this.loan.parcels.forEach((parcel) => {
      parcel.agencies.forEach((agency) => {
        if (!agencyCount[agency.capAgency]) {
          agencyCount[agency.capAgency] = 0
        }
        agencyCount[agency.capAgency] += 1;
      })
    });

    const timeNow = DateTime.local();
    const currentShortDate = timeNow.toFormat('LL/dd');
    const currentLongDate = timeNow.toFormat('y/LL/dd');
    this.loan.parcels.forEach((parcel) => {
      parcel.maxAAgency = '';
      parcel.maxAValue = 0;
      parcel.maxASeqNumber = 99999;
      let closestDueDate = '';
      let closestDueDateDisplayString = '';
      parcel.agencies.forEach((agency) => {
        if (agencyCount[agency.capAgency] > parcel.maxAValue) {
          parcel.maxAValue = agencyCount[agency.capAgency];
          parcel.maxAAgency = agency.capAgency;
          parcel.maxASeqNumber = agency.sequenceNumber;
        }
        agency.escrowHistory.forEach((history) => {
          let tempDueDateYear: string | number = timeNow.year;
          if (history.dueDate && history.dueDate !== '') {
            let tempDueDate = `${history.dueDate}`;
            if (history.year && history.year !== '') {
              tempDueDate = `${history.year}/${tempDueDate}`;
              tempDueDateYear = history.year;
            } else if (tempDueDate >= currentShortDate) {
              tempDueDate = `${timeNow.year}/${tempDueDate}`;
            } else {
              tempDueDate = `${timeNow.year + 1}/${tempDueDate}`;
              tempDueDateYear = timeNow.year + 1;
            }
            if (closestDueDate === '') {
              if (tempDueDate >= currentLongDate) {
                closestDueDate = tempDueDate;
                closestDueDateDisplayString = `${history.dueDate}/${tempDueDateYear}`;
              }
            } else if (tempDueDate < closestDueDate) {
              closestDueDate = tempDueDate;
              closestDueDateDisplayString = `${history.dueDate}/${tempDueDateYear}`;
            }
          }
        })
        agency.nonEscrowHistory.forEach((history) => {
          if (history.dueDate && history.dueDate !== '') {
            let tempDueDate = `${history.dueDate}`;
            if (history.year && history.year !== '') {
              tempDueDate = `${history.year}/${tempDueDate}`;
            } else if (tempDueDate >= currentShortDate) {
              tempDueDate = `${timeNow.year}/${tempDueDate}`;
            } else {
              tempDueDate = `${timeNow.year + 1}/${tempDueDate}`;
            }
            if (closestDueDate === '') {
              if (tempDueDate >= currentLongDate) {
                closestDueDate = tempDueDate;
                closestDueDateDisplayString = history.dueDate;
              }
            } else if (tempDueDate < closestDueDate) {
              closestDueDate = tempDueDate;
              closestDueDateDisplayString = history.dueDate;
            }
          }
        });
      });
      parcel.closestDueDate = closestDueDateDisplayString;
      parcel.agencies = sortBy(parcel.agencies, ['sequenceNumber'])
    })
    this.loan.parcels = sortBy(this.loan.parcels, ['parcelNumber']);
  }

  getActiveParcels(loan: Loan): Parcel[] {
    return loan.parcels.filter((parcel) => parcel.active);
  }

  async parcelChanged(event: { index: number, key: string, op: string, value: any }) {
    function nestedFieldAccess(obj: any, objKey: string) {
      objKey = objKey.replace(/\[(\w+)\]/g, '.$1');
      objKey = objKey.replace(/^\./, '');
      const a = objKey.split('.');
      for (let i = 0, n = a.length; i < n; i += 1) {
        const k = a[i];
        if (k in obj) {
          obj = obj[k];
        } else {
          return obj;
        }
      }
      return obj;
    }

    if (event.op === 'replace') {
      // if all parcels marked inactive and loan is NOT yet inactive, handle...
      if (event && event.key && event.key === 'active') {
        if (event.value === 'false' && this.getActiveParcels(this.loan).length === 0 && this.loan.active === true) {
          this.loan.active = false;
          this.$v.loan.active.$touch();
          this.loan.loanDateInactive = DateTime.local().toFormat('MM/dd/yyyy');
          this.$v.loan.loanDateInactive.$touch();
          this.setInactiveOnParcelsInactive = true;
        } else if (event.value === 'true' && this.loan.active === false) {
          this.setActiveOnParcelsActive = true;
          this.loan.active = true;
          this.$v.loan.active.$touch();
          this.loan.loanDateInactive = '';
          this.$v.loan.loanDateInactive.$touch();
        }
      }

      nestedFieldAccess(this.$v.loan.parcels.$each[event.index], event.key).$touch();
    } else if (event.op === 'add') {
      const collectionKey = event.key.split('.')[event.key.split('.').length - 1];
      switch (collectionKey) {
        case 'agencies': {
          await this.createNewAgency(this.loan.parcels[event.index], event.value.agencyId);
          break;
        }

        case 'escrowHistory': {
          let escrowIds: Map<any, any> = this.addedEscrows[event.index];
          if (!escrowIds) {
            escrowIds = new Map();
            escrowIds.set(event.value.agencyId, [Object.assign(event.value, { $new: true })]);
          } else {
            const mapEntry = escrowIds.get(event.value.agencyId);
            if (mapEntry) {
              mapEntry.push(Object.assign(event.value, { $new: true }));
            } else {
              escrowIds.set(event.value.agencyId, [Object.assign(event.value, { $new: true })]);
            }
          }
          this.addedEscrows[event.index] = escrowIds;
          break;
        }

        case 'nonEscrowHistory': {
          let nonEscrowIds: Map<any, any> = this.addedNonEscrows[event.index];
          if (!nonEscrowIds) {
            nonEscrowIds = new Map();
            nonEscrowIds.set(event.value.agencyId, [Object.assign(event.value, { $new: true })]);
          } else {
            const mapEntry = nonEscrowIds.get(event.value.agencyId);
            if (mapEntry) {
              mapEntry.push(Object.assign(event.value, { $new: true }));
            } else {
              nonEscrowIds.set(event.value.agencyId, [Object.assign(event.value, { $new: true })]);
            }
          }
          this.addedNonEscrows[event.index] = nonEscrowIds;
          break;
        }

        default:
          break;
      }
    } else if (event.op === 'remove') {
      const collectionKey = event.key.split('.')[event.key.split('.').length - 1];
      switch (collectionKey) {
        case 'agencies': {
          let agencyIds = this.removedAgencies[event.index];
          if (!agencyIds) {
            agencyIds = [];
          }
          agencyIds.push(event.value.parcelAgencyId);
          this.removedAgencies[event.index] = agencyIds;
          break;
        }

        case 'escrowHistory': {
          const existingEscrows = this.addedEscrows[event.index];
          if (existingEscrows) {
            const agencyEscrows = existingEscrows.get(event.value.agencyId);
            if (agencyEscrows) {
              const alreadyAddedIndex = agencyEscrows.findIndex(
                (existingEscrow) => existingEscrow === event.value,
              );
              if (alreadyAddedIndex !== -1) {
                agencyEscrows.splice(alreadyAddedIndex, 1);
                break;
              }
            }
          }

          let escrowIds: Map<any, any[]> = this.removedEscrows[event.index];
          if (event.value.$new) {
            if (!escrowIds) {
              break;
            } else {
              const mapEntry = escrowIds.get(event.value.agencyId);
              if (mapEntry) {
                escrowIds.set(event.value.agencyId, mapEntry.filter((entry) => entry !== event.value));
              } else {
                break;
              }
            }
          } else {
            if (!escrowIds) {
              escrowIds = new Map();
              escrowIds.set(event.value.agencyId, [event.value]);
            } else {
              const mapEntry = escrowIds.get(event.value.agencyId);
              if (mapEntry) {
                mapEntry.push(event.value);
              } else {
                escrowIds.set(event.value.agencyId, [event.value]);
              }
            }
            this.removedEscrows[event.index] = escrowIds;
          }
          break;
        }

        case 'nonEscrowHistory': {
          const existingNonEscrows = this.addedNonEscrows[event.index];
          if (existingNonEscrows) {
            const agencyNonEscrows = existingNonEscrows.get(event.value.agencyId);
            if (agencyNonEscrows) {
              const alreadyAddedIndex = agencyNonEscrows.findIndex(
                (existingNonEscrow) => existingNonEscrow.agencyId === event.value.agencyId && !existingNonEscrow.parcelNonEscrowHistoryId,
              );
              if (alreadyAddedIndex !== -1) {
                agencyNonEscrows.splice(alreadyAddedIndex, 1);
                break;
              }
            }
          }

          let nonEscrowIds = this.removedNonEscrows[event.index];
          if (event.value.$new) {
            if (!nonEscrowIds) {
              break;
            } else {
              const mapEntry = nonEscrowIds.get(event.value.agencyId);
              if (mapEntry) {
                nonEscrowIds.set(event.value.agencyId, mapEntry.filter((entry) => entry !== event.value));
              } else {
                break;
              }
            }
          } else {
            if (!nonEscrowIds) {
              nonEscrowIds = new Map();
              nonEscrowIds.set(event.value.agencyId, [event.value]);
            } else {
              const mapEntry = nonEscrowIds.get(event.value.agencyId);
              if (mapEntry) {
                mapEntry.push(event.value);
              } else {
                nonEscrowIds.set(event.value.agencyId, [event.value]);
              }
            }
            this.removedNonEscrows[event.index] = nonEscrowIds;
          }
          break;
        }

        default:
          break;
      }
    }
  }

  async updateLoan(): Promise<boolean> {
    if (!this.$v.loan) {
      console.log('No validation.');
      return true;
    }

    const dirtyInvalidFields = this.nestedInvalidFields
      .filter((field) => at(this.$v.loan, field)[0].$dirty);

    const cleanInvalidFields = this.nestedInvalidFields
      .filter((field) => !at(this.$v.loan, field)[0].$dirty);

    if (this.$v.loan.$invalid) {
      // Do not allow update to continue if a dirtied field is invalid
      if (dirtyInvalidFields.length > 0) {
        console.error('Fields were invalid. Cancelling update.', dirtyInvalidFields);

        // Touch all invalid fields
        cleanInvalidFields.forEach((field) => at(this.$v.loan, field)[0].$touch());
        this.showError({
          text: 'Could not save changes.  One or more form fields are invalid.  Please correct and try again.',
          timeout: 5000,
          closeable: true,
        });
        return false;
      }
    }

    // Specifically test parcel Number/address combos before trying to save
    const parcelNumbers = this.loan.parcels.map((x) => x.parcelNumber);
    let currentIndex = 0;
    for (const parcel of this.loan.parcels) { // eslint-disable-line
      const lastIndex = parcelNumbers.lastIndexOf(parcel.parcelNumber);

      // if current != last && (!=''/null/undefined || address1==address1)
      if (currentIndex !== lastIndex && ((parcel.parcelNumber !== '' && parcel.parcelNumber != null && parcel.parcelNumber !== undefined)
        || (this.loan.parcels[currentIndex].address.value.address1 === this.loan.parcels[lastIndex].address.value.address1
            && this.loan.parcels[currentIndex].address.value.address2 === this.loan.parcels[lastIndex].address.value.address2))) {
        this.showError({
          text: 'Parcel numbers or addresses must be unique per loan.  Please fix the error then save the page.',
          closeable: true,
        });
        return false;
      }

      currentIndex += 1;
    }

    try {
      const payload: JsonPatchPayload = vuelidateToPatch(this.$v.loan, [{
        key: 'problem',
        converter: (value: any): JsonPatchEntry[] => simpleVerifiedConverter(value, 'problem'),
      }], {
        parcels: {
          key: 'parcelId',
          converters: [{
            key: 'address',
            converter: customAddressConverter({
              address1: 'address',
            }),
          }, {
            key: 'mailingAddress',
            converter: customAddressConverter({}, 'mailing'),
          }, {
            key: 'problem',
            converter: (value: any): JsonPatchEntry[] => simpleVerifiedConverter(value, 'problem'),
          }, {
            key: 'taxLastPaidAmount',
            converter: (value: any): JsonPatchEntry[] => currencyFieldConverter(value, 'taxLastPaidAmount'),
          },
          {
            key: 'activePrincipalBalance',
            converter: (value: any): JsonPatchEntry[] => currencyFieldConverter(value, 'activePrincipalBalance'),
          }],
        },
        agencies: {
          key: 'parcelAgencyId',
          converters: [{
            key: 'parcelAgencyVerified',
            converter: (value: any): JsonPatchEntry[] => ([{
              op: JsonPatchOperator.replace,
              path: '/parcel_agency_verified',
              value: value.$model.verified,
            }]),
          }],
        },
        escrowHistory: {
          key: 'parcelEscrowHistoryId',
          converters: [{
            key: 'reportedDate',
            converter: (value: any): JsonPatchEntry[] => falsyNullConverter(value, 'reportedDate'),
          }, {
            key: 'amountPaid',
            converter: (value: any): JsonPatchEntry[] => currencyFieldConverter(value, 'amountPaid'),
          }, {
            key: 'amountReported',
            converter: (value: any): JsonPatchEntry[] => currencyFieldConverter(value, 'amountReported'),
          }, {
            key: 'zeroVerified',
            converter: (value: any): JsonPatchEntry[] => ([{
              op: JsonPatchOperator.replace,
              path: '/zero_verified',
              value: value.$model.verified,
            }]),
          }],
        },
        nonEscrowHistory: {
          key: 'parcelNonEscrowHistoryId',
          converters: [{
            key: 'amountDue',
            converter: (value: any): JsonPatchEntry[] => currencyFieldConverter(value, 'amountDue'),
          }, {
            key: 'base',
            converter: (value: any): JsonPatchEntry[] => currencyFieldConverter(value, 'base'),
          }, {
            key: 'status',
            converter: (value: any): JsonPatchEntry[] => {
              const actualValue = value.$model.value;
              return [{
                op: JsonPatchOperator.replace,
                path: '/status',
                value: actualValue,
              }];
            },
          }],
        },
      });

      if (Object.keys(this.addedAgencies).length > 0) {
        const indices: number[] = Object.keys(this.addedAgencies) as unknown[] as number[];
        indices.forEach((index) => {
          const parcel: Parcel = this.loan.parcels[index];
          this.addedAgencies[index].forEach((agencyId) => {
            payload.push({
              op: JsonPatchOperator.add,
              path: `/parcels/${parcel.parcelId}/agencies/-`,
              value: agencyId,
            });
          })
        });
      }

      if (Object.keys(this.removedAgencies).length > 0) {
        const indices = Object.keys(this.removedAgencies) as unknown[] as number[];
        indices.forEach((index) => {
          const parcel: Parcel = this.loan.parcels[index];
          this.removedAgencies[index].forEach((agencyId) => {
            payload.push({
              op: JsonPatchOperator.remove,
              path: `/parcels/${parcel.parcelId}/agencies/${agencyId}`,
            });
          })
        });
      }

      if (Object.keys(this.addedEscrows).length > 0) {
        const indices = Object.keys(this.addedEscrows) as unknown[] as number[];
        indices.forEach((index) => {
          const parcel: Parcel = this.loan.parcels[index];
          this.addedEscrows[index as number].forEach((escrows, agencyId) => {
            escrows.forEach((escrow) => {
              payload.push({
                op: JsonPatchOperator.add,
                path: `/parcels/${parcel.parcelId}/agencies/${agencyId}/escrow_history/-`,
                value: {
                  agency_id: agencyId,
                  parcel_id: parcel.parcelId,
                  parcel_agency_id: escrow.parcelAgencyId,
                  amount_paid: escrow.amountPaid ? this.$ci.parse(escrow.amountPaid, this.currencyOptions) : null,
                  amount_reported: escrow.amountReported ? this.$ci.parse(escrow.amountReported, this.currencyOptions) : null,
                  recent_correction: escrow.recentCorrection,
                  report_notes: escrow.reportNotes,
                  term: escrow.term,
                  year: escrow.year,
                  zero_verified: escrow.zeroVerified.verified,
                  zero_verified_reason: escrow.zeroVerifiedReason,
                  due_date: escrow.dueDate,
                  reported_date: escrow.reportedDate,
                  batch_number: escrow.batchNumber,
                },
              });
            });
          });
        });
      }

      if (Object.keys(this.removedEscrows).length > 0) {
        const indices = Object.keys(this.removedEscrows) as unknown[] as number[];
        indices.forEach((index) => {
          const parcel: Parcel = this.loan.parcels[index];
          this.removedEscrows[index as number].forEach((escrows, agencyId) => {
            escrows.forEach((escrow) => {
              payload.push({
                op: JsonPatchOperator.remove,
                path: `/parcels/${parcel.parcelId}/agencies/${agencyId}/escrow_history/${escrow.parcelEscrowHistoryId}`,
              });
            });
          });
        });
      }

      if (Object.keys(this.addedNonEscrows).length > 0) {
        const indices = Object.keys(this.addedNonEscrows) as unknown[] as number[];
        indices.forEach((index) => {
          const parcel: Parcel = this.loan.parcels[index];
          this.addedNonEscrows[index as number].forEach((nonEscrows, agencyId) => {
            nonEscrows.forEach((nonEscrow) => {
              payload.push({
                op: JsonPatchOperator.add,
                path: `/parcels/${parcel.parcelId}/agencies/${agencyId}/non_escrow_history/-`,
                value: {
                  agency_id: agencyId,
                  parcel_id: parcel.parcelId,
                  parcel_agency_id: nonEscrow.parcelAgencyId,
                  year: nonEscrow.year,
                  base: nonEscrow.base ? this.$ci.parse(nonEscrow.base, this.currencyOptions) : null,
                  amount_due: nonEscrow.amountDue ? this.$ci.parse(nonEscrow.amountDue, this.currencyOptions) : null,
                  status: nonEscrow.status,
                  notes: nonEscrow.notes,
                  prior_year_status: nonEscrow.priorYearStatus,
                  due_date: nonEscrow.dueDate,
                  reported_date: nonEscrow.reportedDate,
                  status_updated_on: nonEscrow.statusUpdatedOn,
                },
              });
            });
          });
        });
      }

      if (Object.keys(this.removedNonEscrows).length > 0) {
        const indices = Object.keys(this.removedNonEscrows) as unknown[] as number[];
        indices.forEach((index) => {
          const parcel: Parcel = this.loan.parcels[index];
          this.removedNonEscrows[index as number].forEach((nonEscrows, agencyId) => {
            nonEscrows.forEach((nonEscrow) => {
              payload.push({
                op: JsonPatchOperator.remove,
                path: `/parcels/${parcel.parcelId}/agencies/${agencyId}/non_escrow_history/${nonEscrow.parcelNonEscrowHistoryId}`,
              });
            });
          });
        });
      }

      if (payload.length === 0) {
        // TODO: Renotify that no changes were made?
        console.log('No changes were made');
        return true;
      }

      this.updating = true;
      const patchResult = await this.service.updateLoan(this.loanId || this.$route.params.id, payload);
      delete patchResult.files;

      this.$v.$reset();

      // Touch all untouched invalid fields
      cleanInvalidFields.forEach((field) => at(this.$v.loan, field)[0].$touch());

      // Reset all of the various subentity trackers
      this.addedAgencies = {};
      this.removedAgencies = {};
      this.addedEscrows = {};
      this.removedEscrows = {};
      this.addedNonEscrows = {};
      this.removedNonEscrows = {};

      this.$forceUpdate();

      this.getLoan(true);
    } catch (e) {
      if (e.response.status === 412) {
        this.showError({
          text: 'This loan has been modified by another user. Please refresh to make changes.',
          actions: {
            text: 'Refresh',
            function: () => { document.location.reload() },
          },
        });
      } else {
        this.showError({
          text: 'Could not save changes.  There was an issue with the database.',
          timeout: 10000,
          closeable: true,
          actions: {
            text: 'Copy',
            function: this.copyError,
          },
        });
        this.patchError = e.response && e.response.data && e.response.data.detail;
      }
      this.updating = false;
      return false;
    }

    this.updating = false;
    return true;
  }

  private copyError() {
    const errorSummary = {
      error: this.patchError,
      form: {
        dirty: this.dirtyFields,
        invalid: this.nestedInvalidFields,
      },
    };

    this.$copyText(JSON.stringify(errorSummary));
  }

  async createNewParcel(parcel: any) {
    // Built new parcel patch payload
    const payload = [{
      op: JsonPatchOperator.add,
      path: '/parcels/-',
      value: {
        parcel_number: parcel.parcelNumber,
        parcel_type: parcel.parcelType,
        address: parcel.streetAddress,
        date_added: parcel.addedDate,
      },
    }];

    try {
      const updatedLoan = await this.service.updateLoan(this.loanId || this.$route.params.id, payload);

      this.loan.loanType = updatedLoan.loanType;

      updatedLoan.parcels.forEach((updatedLoanParcel) => {
        const existingParcel = this.loan.parcels.find((loanParcel) => loanParcel.parcelId === updatedLoanParcel.parcelId);
        if (!existingParcel) {
          this.loan.parcels.push(updatedLoanParcel);
        }
      });
    } catch (e) {
      if (e.response && e.response.status >= 400) {
        this.showError(`Could not create the new parcel - ${e.response.data.message}. Please try again.`);
      }
    }
  }

  async createNewAgency(parcel: Parcel, agencyId: string) {
    const payload = [{
      op: JsonPatchOperator.add,
      path: `/parcels/${parcel.parcelId}/agencies/-`,
      value: agencyId,
    }];

    try {
      const updatedLoan = await this.service.updateLoan(this.loanId || this.$route.params.id, payload);
      const updatedParcel = updatedLoan.parcels.find((p) => p.parcelId === parcel.parcelId);
      const newAgency = updatedParcel.agencies.find((agency) => agency.agencyId === agencyId);

      // Remove the stub and add the full populated agency
      remove(parcel.agencies, (agency) => !agency.parcelAgencyId);
      parcel.agencies.push(newAgency);
    } catch (e) {
      if (e.response && e.response.status >= 400) {
        this.showError(`Could not create the new parcel agency - ${e.response.data.message}. Please try again.`);
      }
    }
  }

  async updateLoanActive() {
    const previousLoanDateInactive = this.loan.loanDateInactive;
    this.loan.loanDateInactive = this.loan.active ? '' : DateTime.local().toFormat('MM/dd/yyyy');
    this.$v.loan.loanDateInactive.$touch();

    // roll through the parcels, as well.
    for (let i = 0; i < this.loan.parcels.length; i += 1) {
      const parcel = this.loan.parcels[i];
      let deleteIndex: boolean = false;
      if (!('index' in parcel)) {
        deleteIndex = true;
      }
      parcel.index = i;
      if (this.loan.active && !parcel.active) {
        // check the date the parcel was removed
        if (parcel.dateInactive === previousLoanDateInactive) {
          parcel.active = true;
          parcel.dateInactive = '';
          this.parcelChanged({
            index: i, key: 'active', op: 'replace', value: true,
          });
          this.parcelChanged({
            index: i, key: 'dateInactive', op: 'replace', value: '',
          });
        }
      } else if (!this.loan.active && parcel.active) {
        parcel.active = false;
        parcel.dateInactive = DateTime.local().toFormat('MM/dd/yyyy');
        this.parcelChanged({
          index: i, key: 'active', op: 'replace', value: false,
        });
        this.parcelChanged({
          index: i, key: 'dateInactive', op: 'replace', value: DateTime.local().toFormat('MM/dd/yyyy'),
        });
      }
      if (deleteIndex) {
        delete parcel.index;
      }
    }
  }

  async editToggle() {
    let shouldToggle = true;

    if (this.editMode) {
      shouldToggle = await this.updateLoan();
    }

    if (shouldToggle) {
      this.editMode = !this.editMode;
    }
  }

  expandedLoanNotesToggle() {
    this.expandedLoanNotes = !this.expandedLoanNotes;
  }

  expandedBillingNotesToggle() {
    this.expandedBillingNotes = !this.expandedBillingNotes;
  }

  setSelectedAgency(agency: Agency) {
    this.selectedAgency = agency;
  }

  openAgencyPage(agency: Agency) {
    window.open(`/agencies/${agency.agencyId}`, '_blank');
  }

  verifyField(fieldToVerify: Verified, verified: boolean, key: string) {
    fieldToVerify.verified = verified;

    if (verified) {
      fieldToVerify.verifiedBy = this.user;
      fieldToVerify.verifiedOn = new Date();
    } else {
      fieldToVerify.verifiedBy = null;
      fieldToVerify.verifiedOn = null;
    }

    this.$v.loan[key].verified.$touch();
  }

  uploadFiles() {
    return Promise.all(
      this.files.map((file) => this.service.uploadFile(this.loan.loanId, file)),
    ).then(() => {
      this.files = [];
      this.getLoan(true, 'files');
    });
  }

  deleteFile(file: IFile) {
    axios.delete(file.url)
      .then(() => {
        this.loan.files = this.loan.files.filter((oldFile) => oldFile !== file);
      });
  }

  confirmDeletion() {
    this.confirmingDeletion = true;
  }

  dismissDeleteLoanDialog() {
    this.confirmingDeletion = false;
  }

  dismissSetActiveDialog() {
    this.setActiveOnParcelsActive = false;
    this.setInactiveOnParcelsInactive = false;
  }

  handleRollback() {
    this.showSuccess({
      text: 'Rollback successful. Please refresh the page to see changes.',
      actions: {
        text: 'Refresh',
        function: () => { document.location.reload() },
      },
    });
  }

  @Emit('deleted')
  async deleteLoan() {
    const result = await this.service.deleteLoan(this.loanId || this.$route.params.id);
    delete result.files;
    this.loan = { ...this.loan, ...result };
    this.confirmingDeletion = false;
    this.editMode = false;
    this.forceLeave = true;

    return this.loan;
  }
}
