import { DateTime } from 'luxon';

import TaxCollectingFrequency from '@/entities/TaxCollectingFrequency';
import Term from '@/entities/Term';

class TaxCollectingFrequencyUtil {
  public static generateDates(frequency: keyof typeof TaxCollectingFrequency): Date[] {
    switch (frequency) {
      case 'annually':
        return [
          DateTime.fromFormat('01/01', 'MM/dd').toJSDate(),
        ];

      case 'semiannually':
        return [
          DateTime.fromFormat('01/01', 'MM/dd').toJSDate(),
          DateTime.fromFormat('07/01', 'MM/dd').toJSDate(),
        ];

      case 'quarterly':
        return [
          DateTime.fromFormat('01/01', 'MM/dd').toJSDate(),
          DateTime.fromFormat('04/01', 'MM/dd').toJSDate(),
          DateTime.fromFormat('07/01', 'MM/dd').toJSDate(),
          DateTime.fromFormat('10/01', 'MM/dd').toJSDate(),
        ];

      case 'annualOrSemiannually':
        return [
          DateTime.fromFormat('01/01', 'MM/dd').toJSDate(),
          DateTime.fromFormat('01/01', 'MM/dd').toJSDate(),
          DateTime.fromFormat('07/01', 'MM/dd').toJSDate(),
        ];

      case 'annualOrQuarterly':
        return [
          DateTime.fromFormat('01/01', 'MM/dd').toJSDate(),
          DateTime.fromFormat('01/01', 'MM/dd').toJSDate(),
          DateTime.fromFormat('04/01', 'MM/dd').toJSDate(),
          DateTime.fromFormat('07/01', 'MM/dd').toJSDate(),
          DateTime.fromFormat('10/01', 'MM/dd').toJSDate(),
        ];

      default:
        return [];
    }
  }

  public static generateTerms(frequency: keyof typeof TaxCollectingFrequency): Term[] {
    switch (frequency) {
      case 'annually':
        return [
          Term.annual,
        ];

      case 'semiannually':
        return [
          Term.firstHalf,
          Term.secondHalf,
        ];

      case 'quarterly':
        return [
          Term.firstQuarter,
          Term.secondQuarter,
          Term.thirdQuarter,
          Term.fourthQuarter,
        ];

      case 'annualOrSemiannually':
        return [
          Term.annual,
          Term.firstHalf,
          Term.secondHalf,
        ];

      case 'annualOrQuarterly':
        return [
          Term.annual,
          Term.firstQuarter,
          Term.secondQuarter,
          Term.thirdQuarter,
          Term.fourthQuarter,
        ];

      default:
        return [];
    }
  }
}

export { TaxCollectingFrequencyUtil as default };
