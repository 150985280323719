var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.searchCriteria,"label":"Search by...","return-object":""},on:{"change":_vm.updateSearchBy},model:{value:(_vm.currentSearchBy),callback:function ($$v) {_vm.currentSearchBy=$$v},expression:"currentSearchBy"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('form',{attrs:{"autocomplete":"off","action":"#"}},[_c('input',{staticStyle:{"display":"none"},attrs:{"autocomplete":"off","name":"hidden","type":"text"}}),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","hide-details":""},on:{"input":_vm.searchFieldUpdated},model:{value:(_vm.agencySearch),callback:function ($$v) {_vm.agencySearch=$$v},expression:"agencySearch"}})],1)])],1)],1),_c('v-data-table',{staticClass:"elevation-1 table-style",attrs:{"items":_vm.agencies,"headers":_vm.columns,"search":_vm.agencySearch,"server-items-length":_vm.totalAgencies,"loading":_vm.isLoading,"footer-props":{
        'items-per-page-options': [10, 50, 100, 1000]
      }},on:{"click:row":_vm.handleRowClick,"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.dateAdded",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commonDate")(item.dateAdded))+" ")]}},{key:"item.parcelConfigurations",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_vm._l((item.parcelConfigurations),function(config,index){return _c('div',{key:index},[(index < _vm.parcelConfigurationLimit)?_c('div',[_vm._v(_vm._s(config.configuration))]):_vm._e()])}),(item.parcelConfigurations.length > _vm.parcelConfigurationLimit)?_c('div',[_vm._v("…")]):_vm._e()],2)]}},{key:"item.countyLines",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("countyLines")(item.countyLines))+" ")]}},{key:"item.parcelCoding",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parcelCoding")(item.parcelCoding))+" ")]}},{key:"item.relatedAgencies",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"py-2"},_vm._l((item.relatedAgencies),function(agency,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(agency.agencyCode)+" ")])}),0)]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" Your search for \""+_vm._s(_vm.agencySearch)+"\" found no results. ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }