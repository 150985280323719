


































































































































































































import {
  Component, Prop, Emit, Watch, Mixins,
} from 'vue-property-decorator';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import { InputValidationRule } from 'vuetify';

import { fullDate } from '@/validations/vuetify';

import EscrowType from '@/entities/EscrowType';
import Parcel from '@/entities/Parcel';
import Address from '@/entities/Address';

import ParcelDetail from '@/views/loans/ParcelDetail.vue';
import VerifiedCheckbox from '@/components/VerifiedCheckbox.vue';

import GlobalNotifications from '@/mixins/GlobalNotifications.vue';
import UserPermissions from '@/mixins/UserPermissions.vue';
import Verified from '@/entities/Verified';
import nestedFieldAccess from '@/helpers/objectUtils';

@Component({
  components: {
    ParcelDetail,
    VerifiedCheckbox,
  },
})
export default class ParcelList extends Mixins(GlobalNotifications, UserPermissions) {
  @Prop({
    type: Array,
    default: (): Parcel[] => [],
  }) private readonly parcels!: Parcel[];

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly editMode!: boolean;

  @Watch('value')
  onValueChanged(newVal: Parcel[], oldVal: Parcel[]) {
    this.parcels.map((p) => false);
  }

  private parcelTypeOptions = Object.keys(EscrowType).filter((k) => typeof EscrowType[k as keyof typeof EscrowType] === 'string').map((k) => ({
    text: capitalCase(k),
    value: EscrowType[k as keyof typeof EscrowType],
  })).filter((option) => option.value !== '');

  private isNotPaidStatus(value: any) {
    return value.status.value != null && value.status.value !== '' && value.status.value !== 'PAID'
  }

  private getNonEscrowNonPaidList() {
    const val = (this.parcels == null || this.parcels.length === 0) ? [] : this.parcels.map((parcel) => ((parcel.agencies == null || parcel.agencies.length === 0) ? null : parcel.agencies.map((parcelagency) => {
      // instead of filter, insert a null for anything not paid
      const notpaidlist = parcelagency.nonEscrowHistory.filter((neh) => this.isNotPaidStatus(neh));
      return (notpaidlist != null && notpaidlist.length > 0) ? notpaidlist.map((neh) => /* this.isNotPaidStatus(neh) ? */ ({
        id: neh.parcelNonEscrowHistoryId, agency: parcelagency.name, year: neh.year, status: neh.status.value,
      })) : null;
    })));
    const finalArray = (val && val.length > 0) ? val.reduce((accum, current) => (accum ? accum.concat(current) : accum.concat([null])), []) : [[]];
    return finalArray;
  }

  private nonEscrowNonPaidList = this.getNonEscrowNonPaidList();

  // Parcel creation tracking
  private addingParcel: boolean = false;
  private isSavingParcel: boolean = false;
  private rules: { [index: string]: InputValidationRule } = {
    required: (value: any) => !!value || 'Required.',
    parcelUnique: (value: any) => !(value && value !== '' && this.parcels.find((element) => (element.parcelNumber && element.parcelNumber.trim().toUpperCase() === value.trim().toUpperCase()))) || 'Parcel number already exists on loan.',
    addressUnique: (value: any) => !(value && (this.parcelNumber == null || this.parcelNumber === '') && this.parcels.find((element) => (element.address && element.address.value && element.address.value.address1 && element.address.value.address1.trim().toUpperCase() === value.trim().toUpperCase()))) || 'Cannot have multiple  blank parcels with the same address.',
    validDate: fullDate,
  };
  private parcelNumber: string = null;
  private parcelType: string = null;
  private streetAddress: string = null;
  private addedDate: string = null;
  private todaysDate = DateTime.local().toFormat('MM/dd/yyyy');

  private isParcelOpen = this.parcels.map((p) => false);

  private parcelCreateParcelAddressError = false;

  createParcel(parcelNumber: string, streetAddress: string, parcelType: string, addedDate: string) {
    if (!(this.$refs.form as any).validate()) {
      return;
    }

    this.parcelCreateParcelAddressError = false;

    // If the parcel number was blank and the address field was blank
    if ((parcelNumber == null || parcelNumber.trim() === '') && (streetAddress == null || streetAddress.trim() === '')) {
      this.parcelCreateParcelAddressError = true;
      return;
    }

    this.addingParcel = false;

    this.$emit('created', {
      parcelNumber,
      parcelType,
      streetAddress,
      addedDate,
    });

    // reset so the user can create another
    this.parcelNumber = '';
    this.parcelType = '';
    this.streetAddress = '';
    this.addedDate = '';
  }

  fullAddress(address: Address) {
    return Address.fullAddress(address);
  }

  escrowSummary(parcel: Parcel) {
    switch (parcel.parcelType) {
      case EscrowType.escrow:
        return 'E';

      case EscrowType.nonEscrow:
        return 'N';

      case EscrowType.both:
        return 'EN';

      default:
        return '?';
    }
  }

  escrowColor(parcel: Parcel) {
    if (!parcel.active || parcel.deleted) {
      return 'grey';
    }

    switch (parcel.parcelType) {
      case EscrowType.escrow:
        return 'green';

      case EscrowType.nonEscrow:
        return 'amber';

      case EscrowType.both:
        return 'blue';

      default:
        return 'grey';
    }
  }

  toggleActivate(parcel: Parcel, flag: boolean) {
    if (!this.editMode) {
      return;
    }
    const parcelIndex = this.parcels.findIndex((p) => p.parcelId === parcel.parcelId);
    this.parcels[parcelIndex].active = flag;
    this.parcels[parcelIndex].dateInactive = flag ? '' : DateTime.local().toFormat('MM/dd/yyyy');
    this.parcelChanged(parcelIndex, { key: 'active', value: flag.toString(), op: 'replace' });
    this.parcelChanged(parcelIndex, { key: 'dateInactive', value: this.parcels[parcelIndex].dateInactive, op: 'replace' });
  }

  verifyParcel(verified: boolean, parcel: Parcel, keyToChange: string = 'verified') {
    if (!this.editMode) {
      return;
    }

    const index = this.parcels.findIndex((p) => p.parcelId === parcel.parcelId);

    if (keyToChange === 'problem') {
      parcel.problem.verified = verified;
      if (verified) {
        parcel.problem.verifiedBy = this.user;
        parcel.problem.verifiedOn = new Date();
      } else {
        parcel.problem.verifiedBy = null;
        parcel.problem.verifiedOn = null;
      }
    } else {
      parcel.verified = verified;
      if (verified) {
        parcel.verifiedBy = this.user;
        parcel.verifiedOn = new Date();
      } else {
        parcel.verifiedBy = null;
        parcel.verifiedOn = null;
      }
    }

    this.parcelChanged(index, { key: keyToChange, value: verified, op: 'replace' });
  }

  @Emit('change')
  parcelChanged(index: number, event: { key: string, value: any, op: string }) {
    return {
      index,
      key: event.key,
      value: event.value,
      op: event.op,
    };
  }
}
