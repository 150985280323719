
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

@Component({
  name: 'font-calculation',
})
export default class FontCalculation extends Vue {
  @Prop({
    type: Object,
    default: () => ({
      maxWidth: 750,
      minWidth: 350,
      maxRatio: 1,
      minRatio: 0.5,
    }),
  }) private readonly fontSizeRules!: any;

  private fontSize: number = 1;

  // Hooks
  created() {
    window.addEventListener('resize', this.resizeHandler);
  }

  mounted() {
    this.resizeHandler();
  }

  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  // Methods
  resizeHandler(e?: UIEvent) {
    this.fontSize = this.calculateFontRatio();
  }

  calculateFontRatio() {
    if (this.$el && this.$el.clientWidth) {
      const width = this.$el.clientWidth;
      if (width > this.fontSizeRules.maxWidth) {
        return this.fontSizeRules.maxRatio;
      }
      if (width <= this.fontSizeRules.minWidth) {
        return this.fontSizeRules.minRatio;
      }
      const diff = width - this.fontSizeRules.minWidth;
      const ratio = diff / (this.fontSizeRules.maxWidth - this.fontSizeRules.minWidth);
      return (ratio * (this.fontSizeRules.maxRatio - this.fontSizeRules.minRatio)) + this.fontSizeRules.minRatio;
    }

    return 1;
  }
}
