
import {
  Component,
  Prop,
  Emit,
  Vue,
  Mixins,
} from 'vue-property-decorator';
import { Validation, validationMixin } from 'vuelidate';

@Component({
  name: 'control-list',
  validations() {
    const that = this as ControlList;
    if (that.validator) {
      return {
        newEntity: that.validator,
      };
    }

    return {
      newEntity: {},
    };
  },
})
export default class ControlList extends Mixins(validationMixin) {
  @Prop({
    type: Array,
    default: (): any[] => [],
  }) private readonly value!: any[];

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly editMode!: boolean;

  @Prop({
    type: String,
    default: '',
  }) private readonly nameKey!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly immediateAdd!: boolean;

  @Prop({
    type: Object,
    default: () => {},
  }) private readonly options!: any;

  @Prop({
    type: Object,
    default: null,
  }) private readonly validator!: any;

  private newEntity: any = null;
  private immediateAddMode: boolean = false;

  // Computed
  get allEntities(): any[] {
    return [...this.value, this.newEntity].filter((entity) => entity !== null);
  }

  get addMode(): boolean {
    return (this.newEntity !== null || this.immediateAddMode) && this.editMode;
  }

  // Methods
  addEntity() {
    if (this.immediateAdd) {
      this.newEntity = null;
      this.immediateAddMode = false;
      return;
    }

    this.$emit('added', this.newEntity);
    const newArray = [...this.value, this.newEntity];
    this.newEntity = null;
    this.$emit('input', newArray);
  }

  updateEntity(value: any) {
    this.newEntity = value;
    this.addEntity();
  }

  @Emit('input')
  removeEntity(entityToRemove: any) {
    this.$emit('removed', entityToRemove);
    return this.value.filter((entity) => entity !== entityToRemove);
  }

  // Hooks / Overrides
  render() {
    return this.$scopedSlots.default({
      // Data slot props
      entities: this.allEntities,
      editMode: this.editMode,
      addMode: this.addMode,
      newEntity: this.newEntity,
      updateEntity: this.updateEntity,
      options: this.options,
      validator: this.$v.newEntity,

      // Action slot props
      addNewEntity: () => {
        this.newEntity = this.nameKey ? { [this.nameKey]: '' } : '';

        if (this.options && this.options.nameOfList === 'Escrow History') {
          this.newEntity.zeroVerified = {
            verified: false,
          };
        }

        if (this.immediateAdd) {
          this.immediateAddMode = true;
          this.$emit('added', this.newEntity);
          const newArray = [...this.value, this.newEntity];
          this.$emit('input', newArray);
          this.newEntity = null;
        }
      },
      clearNewEntity: () => {
        this.newEntity = null;
      },
      removeEntity: this.removeEntity,

      // Binding slot props
      inputAttrs: {
        value: this.newEntity,
      },
      inputEvents: {
        click: () => { this.addEntity() },
        keydown: (e: KeyboardEvent) => {
          if (e.keyCode === 13) {
            e.preventDefault();
            this.addEntity();
          }
        },
      },
    });
  }
}
