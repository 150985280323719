import Address from '@/entities/IAddress';
import Verified from '@/entities/Verified';
import User from '@/entities/User';
import IContact from '@/entities/IContact';
import TaxCollectingFrequency from '@/entities/TaxCollectingFrequency';
import IFile from '@/entities/IFile';
import StatusTrackable from '@/entities/StatusTrackable';

export enum DelinquentTaxCollectingYear {
  prior = 'Prior Year',
  current = 'Current Year',
  next = 'Upcoming Year',
}

export class DelinquentTaxCollectingYearUtil {
  public static collectingYearToYear(collectingYear: keyof typeof DelinquentTaxCollectingYear): string {
    const currentYear = (new Date()).getFullYear();
    switch (collectingYear) {
      case 'prior':
        return (currentYear - 1).toString();

      case 'current':
        return (currentYear).toString();

      case 'next':
        return (currentYear + 1).toString();

      default:
        return currentYear.toString();
    }
  }
}

export type IParcelConfiguration = StatusTrackable & {
  agencyConfigurationId?: string,
  configuration: string,
}

export interface IDelinquentTaxCollectingOffice {
  agencyId?: string,
  capAgency?: string,
  name?: string,
  website?: string,
  collectingFrequency?: keyof typeof TaxCollectingFrequency,
  collectingYear?: keyof typeof DelinquentTaxCollectingYear,
  nonEscrowCollectingYear?: keyof typeof DelinquentTaxCollectingYear,
  payableTo?: string,
  address1?: string,
  address2?: string,
  city?: string,
  county?: string,
  state?: string,
  zipCode?: string,
}

export type IAgencyContact = IContact & StatusTrackable & {
  agencyContactId?: string
}

export type ITaxProcessor = StatusTrackable & {
  agencyTaxProcessorId?: string,
  taxOffice?: string,
  agencyCode?: string,
  type?: string,
  contact?: string,
  phone?: string,
  fax?: string,
  email?: string,
  mortgageCode?: string,
  notes?: string,
  mailing1?: string,
  mailing2?: string,
}

export type IRelatedAgency = StatusTrackable & {
  relatedAgencyId?: string,
  agencyId?: string,
  name?: string,
  county?: string,
  state?: string,
  agencyCode?: string,
}

export type ICollectingEntry = StatusTrackable & {
  agencyCollectingScheduleId?: string,
  agencyId?: string,
  term?: string,
  billRequestDate?: string,
  billReleaseDate?: string,
  dueDate?: Verified<string>
  taxBillCheckIn: Verified<void>,
  lastDayToPay?: string,
  lenderId?: string,
}

export type ICollectingSchedule = ICollectingEntry[]

export default interface IAgency {
  agencyId: string;
  name: string;

  capAgency?: string;
  taxType?: string;
  postMarkIndicator: boolean;
  duplicateBillFee?: boolean;
  duplicateBillAmount?: number;
  cooperationIndicator: boolean;
  website?: string;
  dueDateNotes?: string;
  auditStartDate?: string;
  auditNotes?: string;
  taxPayableTo?: string;
  phoneNumber?: string;
  taxBillCollection?: string;
  payableTo?: string;
  billsMailed?: string;
  countyLines?: string;
  countyLinesNotes?: string;
  parcelCoding?: string;
  agencyLenders?: boolean;
  capitalNotes?: string;
  discountedBill?: boolean;

  // Data System
  dataSystemUsed?: string;
  publicTerminalsAvailable?: number;
  currentTaxWebsite?: string;
  delinquentTaxWebsite?: string;

  // Collection information
  nonEscrowCollectingYear?: string,
  collectingFrequency?: string,
  collectingYear?: string,
  collectingSchedule?: ICollectingSchedule,

  // Address
  address?: Verified<Address>;

  // Websites
  agencyWebsite?: string;
  taxWebsite?: string;
  priorTaxWebsite?: string;

  // Phone number
  collectorPhone?: string;
  assessorPhone?: string;

  // Collections and sub objects
  parcelConfigurations: IParcelConfiguration[];
  delinquentTaxCollectingOffice?: IDelinquentTaxCollectingOffice;
  contacts: IAgencyContact[];
  taxProcessors: ITaxProcessor[];
  relatedAgencies: IRelatedAgency[];

  // Files
  files?: IFile[],

  agencyVerified: boolean;
  agencyVerifiedBy?: User;
  agencyVerifiedOn?: Date;
}
