export default function nestedFieldAccess(obj: any, objKey: string) {
  objKey = objKey.replace(/\[(\w+)\]/g, '.$1');
  objKey = objKey.replace(/^\./, '');
  const a = objKey.split('.');
  for (let i = 0, n = a.length; i < n; i += 1) {
    const k = a[i];
    if (k in obj) {
      obj = obj[k];
    } else {
      return obj;
    }
  }
  return obj;
}
