


















































































import {
  Component, Mixins, Prop,
} from 'vue-property-decorator';
import { Action } from 'vuex-class';
import _ from 'lodash';

import AgenciesList from '@/views/agencies/AgenciesList.vue';
import AgenciesDetail from '@/views/agencies/AgenciesDetail.vue';
import AgencyService from '@/services/agencies';
import Agency from '@/entities/Agency';

import FontCalculation from '@/mixins/FontCalculation.vue';
import UserPermissions from '@/mixins/UserPermissions.vue';

@Component({
  name: 'agencies',
  components: {
    AgenciesList,
    AgenciesDetail,
  },
})
export default class Agencies extends Mixins(FontCalculation, UserPermissions) {
  @Prop({
    type: String,
  }) private readonly view!: string;

  @Prop({
    type: Boolean,
    default: true,
  }) private readonly showTitle!: boolean;

  @Action('showSuccess') showSuccess!: (val: any) => {};

  private agencies: Agency[] = [];
  private currentView = 0;
  private agencyService: AgencyService = new AgencyService();
  private isLoading = false;
  private errorMessage = 'Unable to create new agency. Please try again later.';
  private agency: Agency = null;

  private errorDialog = false;
  private newAgency = false;
  private agencyNumber = '';
  private agencyName = '';
  private rules: any = {
    required: (value: any) => !!value || 'Required.',
  };

  // Computed
  get selectedAgency(): boolean {
    return Boolean(this.$route.params.id);
  }

  get hasTitle(): boolean {
    return this.view !== 'panel-wall' && this.showTitle;
  }

  async createAgency(agencyNumber: string, agencyName: string) {
    if (!(this.$refs.form as any).validate()) {
      return;
    }

    this.isLoading = true;
    const payload = {
      cap_agency: agencyNumber,
      name: agencyName,
    };

    try {
      const agencyResponse = await this.agencyService.addAgency(payload);
      this.newAgency = false;
      if (agencyResponse.agencyId !== undefined) {
        this.agencyNumber = '';
        this.agencyName = '';
        this.handleSelectedAgency(agencyResponse, true);
      }
    } catch (e) {
      if (e.response && e.response.status >= 400) {
        this.errorMessage = e.response.data.message;
        this.errorDialog = true;
      }
    } finally {
      this.isLoading = false;
    }
  }

  handleSelectedAgency(agency: Agency, edit = false) {
    if (this.view === 'panel-wall') {
      this.agency = agency;
    } else {
      const queryParams = edit ? { editMode: edit.toString() } : {};
      this.$router.push({ path: `/agencies/${agency.agencyId}`, query: queryParams });
    }
  }

  clearSelection(deletedAgency?: Agency) {
    if (deletedAgency) {
      this.showSuccess(`Successfully deleted agency <b>${deletedAgency.name}</b>`);
    }

    if (this.view === 'panel-wall') {
      this.agency = null;
    } else {
      this.$router.push({ path: '/agencies' });
    }
  }
}
