
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { Validation } from 'vuelidate';

function errorMessageGenerator(field: string, errorMessage: string | ((validationObj: Validation) => string)) {
  return (validationObj: any) => {
    const errors: string[] = [];
    if (!validationObj || !validationObj.$dirty) return errors;

    if (!validationObj[field]) {
      errors.push(typeof errorMessage === 'string' ? errorMessage : errorMessage(validationObj));
    }

    return errors;
  }
}

@Component({
  name: 'validation-errors',
})
export default class ValidationErrors extends Vue {
  get emailErrors() {
    return errorMessageGenerator(
      'email',
      (validationObj) => {
        const errorMessage = 'Must be a valid email.';
        const value = validationObj.$model as string;
        const split = value.split('@');

        if (split.length === 2) {
          const [, domain] = split;

          if (domain.match(/[A-Z]/g)) {
            return `${errorMessage} Domain name must be lowercase.`;
          }
        }

        return errorMessage;
      },
    );
  }

  get zipCodeErrors() {
    return errorMessageGenerator('usZipCode', 'Must be a valid 5-digit or 9-digit zip code');
  }

  get phoneErrors() {
    return errorMessageGenerator('phoneNumber', 'Must be a valid phone number');
  }

  get permissiveUrlErrors() {
    return errorMessageGenerator('permissiveUrl', 'Must be a valid URL');
  }

  get decimalErrors() {
    return errorMessageGenerator('decimal', 'Must be a valid decimal number');
  }

  get integerErrors() {
    return errorMessageGenerator('integer', 'Must be a valid integer number');
  }

  get fullDateErrors() {
    return errorMessageGenerator('fullDate', 'Must be a valid MM/DD/YYYY date');
  }

  get shortDateErrors() {
    return errorMessageGenerator('shortDate', 'Must be a valid MM/DD date');
  }

  get stateTerritoryAbbrErrors() {
    return errorMessageGenerator('stateTerritoryAbbr', 'Must be a valid state or territory')
  }

  get uniqueParcelNumberErrors() {
    return errorMessageGenerator('uniqueParcelNumber', 'Parcel numbers must be unique per loan')
  }
}
