
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  Component, Prop, Watch, Mixins, InjectReactive,
} from 'vue-property-decorator';
import { validationMixin, Validation } from 'vuelidate';
import { capitalCase } from 'change-case';
import _ from 'lodash';
import { DataTableHeader } from 'vuetify';

import EscrowType from '@/entities/EscrowType';
import Parcel, { ParcelAgency } from '@/entities/Parcel';
import IclOclType from '@/entities/IclOclType';
import Agency from '@/entities/Agency';
import { DelinquentTaxCollectingYear, DelinquentTaxCollectingYearUtil } from '@/entities/IAgency';
import Term from '@/entities/Term';

import AgencyService from '@/services/agencies';

import VerifiedCheckbox from '@/components/VerifiedCheckbox.vue';
import ControlList from '@/components/collections/ControlList.vue';

import nestedFieldAccess from '@/helpers/objectUtils';

import {
  usZipCode, fullDate, shortDate, stateTerritoryAbbr,
} from '@/validations';
import ValidationErrors from '@/mixins/ValidationErrors.vue';
import UserPermissions from '@/mixins/UserPermissions.vue';
import CurrencyFormatter from '@/mixins/CurrencyFormatter.vue';

import Verified from '@/entities/Verified';
import User from '@/entities/User';

const validations: any = {
  value: {
    parcelId: {},
    loanId: {},
    loanNumber: {},
    lenderNumber: {},
    parcelNumber: { },

    address: {
      value: {
        address1: {},
        address2: {},
        city: {},
        state: { stateTerritoryAbbr },
        zipCode: { usZipCode },
        county: {},
        lot: {},
        block: {},
        unit: {},
        building: {},
      },
      verified: {},
    },

    mailingAddress: {
      value: {
        address1: {},
        address2: {},
        city: {},
        state: { stateTerritoryAbbr },
        zipCode: { usZipCode },
        county: {},
      },
      verified: {},
    },

    legal: {},
    deleted: {},

    escrowDateDelqSearched: {},
    escrowDelqSearchNotes: {},
    parcelType: {},
    countyLines: {},
    idTag: {},
    sequenceNumber: {},
    parcelNotes: {},
    problem: {
      value: {},
      verified: {},
    },
    activePrincipalBalance: {},
    typeDescription: {},
    collected: {},
    taxLastPaidAmount: {},
    taxPeriodPaid: {},
    taxPaidDate: { fullDate },
    maturityDate: { fullDate },
    whenBilled: {},
    billedReport: {},
    billNumber: {},
    lenderNotes: {},
    legalNotes: {},
    altParcelNumber: {},
    originalNoteDate: { fullDate },

    agencies: {
      $each: {
        escrowHistory: {
          $each: {
            year: {},
            term: {},
            dueDate: { shortDate },
            reportedDate: { fullDate },
            amountPaid: {},
            amountReported: {},
            zeroVerified: {},
            zeroVerifiedReason: {},
            reportedBy: {},
            reportNotes: {},
            recentCorrection: {},
            batchNumber: {},
          },
        },
        nonEscrowHistory: {
          $each: {
            year: {},
            base: {},
            amountDue: {},
            status: {},
            notes: {},
            dueDate: { fullDate },
            reportedDate: { fullDate },
            statusUpdatedOn: { shortDate },
          },
        },
        parcelAgencyVerified: {},
        sequenceNumber: {},
      },
    },

    dateDeleted: {},
    dateInactive: {},
    dateCoded: {},
    dateAdded: { fullDate },

    hasMatchingConfiguration: {},
    verified: {},
    active: {},
  },
};

type DetailTableHeader = DataTableHeader & {
  readonly?: boolean | ((value: any) => boolean),
  type?: string,
  options?: any[],
};

@Component({
  name: 'parcel-detail',
  validations,
  components: {
    VerifiedCheckbox,
    ControlList,
  },
  mixins: [validationMixin],
})
export default class ParcelDetail extends Mixins(UserPermissions, ValidationErrors, CurrencyFormatter) {
  @Prop({
    type: Object,
    default: () => {},
  }) private readonly value!: Parcel;

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly editMode!: boolean;

  @InjectReactive({
    from: 'font',
    default: 1,
  }) private readonly font!: number;

  private newEscrow = {
    year: {},
    term: {},
    dueDate: { shortDate },
    reportedDate: { fullDate },
    amountPaid: {},
    amountReported: {},
    zeroVerified: {},
    zeroVerifiedReason: {},
    reportedBy: {},
    reportNotes: {},
    recentCorrection: {},
    batchNumber: {},
  };

  private newNonEscrow = {
    year: {},
    base: {},
    amountDue: {},
    status: {},
    notes: {},
    dueDate: { fullDate },
    reportedDate: { fullDate },
    statusUpdatedOn: { shortDate },
  };

  private parcelTypeOptions = Object.keys(EscrowType).filter((k) => typeof EscrowType[k as keyof typeof EscrowType] === 'string').map((k) => ({
    text: capitalCase(k),
    value: EscrowType[k as keyof typeof EscrowType],
  })).filter((option) => option.value !== '');

  private yearOptions: string[] = [];
  private escrowCycleYear: string = null;

  private termOptions = Object.keys(Term).filter((k) => typeof Term[k as keyof typeof Term] === 'string').map((k) => ({
    text: Term[k as keyof typeof Term],
    value: k,
  }));

  private countyLineOptions = Object.keys(IclOclType).filter((k) => typeof IclOclType[k as keyof typeof IclOclType] === 'string').map((k) => ({
    text: IclOclType[k as keyof typeof IclOclType],
    value: k,
  }));

  private agencyService: AgencyService = new AgencyService();
  private selectedAgency: ParcelAgency = null;
  private selectedAgencyIndex: number = -1;

  private searchedAgencies: Agency[] = [];

  get escrowHeaders(): DetailTableHeader[] {
    const minimumHeaders = [
      {
        text: 'Year',
        value: 'year',
        sortable: false,
        width: '1%',
        type: 'select',
        options: this.yearOptions,
      },
      {
        text: 'Term',
        value: 'term',
        sortable: false,
        width: '1%',
        type: 'select',
        options: this.termOptions,
      },
      {
        text: 'Due Date',
        value: 'dueDate',
        sortable: false,
        width: '1%',
        type: 'dateShort',
      },
      {
        text: 'Amount Paid',
        value: 'amountPaid',
        readonly: this.isCapitalUserOrTrainee,
        sortable: false,
        width: '1%',
        type: 'currency',
      },
      {
        text: 'Reported Date',
        value: 'reportedDate',
        readonly: this.isCapitalUserOrTrainee,
        sortable: false,
        width: '1%',
        type: 'date',
      },
      {
        text: 'Taxes Due',
        value: 'amountReported',
        sortable: false,
        width: '1%',
        type: 'currency',
      },
      {
        text: 'Zero Verified',
        value: 'zeroVerified',
        readonly: (value: any) => this.isCapitalUserOrTrainee && !value.reportedDate,
        sortable: false,
        width: '1%',
        type: 'verified',
      },
      {
        text: 'Zero Verified Reason',
        value: 'zeroVerifiedReason',
        readonly: (value: any) => this.isCapitalUserOrTrainee && !value.reportedDate,
        sortable: false,
        width: '1%',
      },
      {
        text: 'Reported By',
        value: 'reportedBy',
        readonly: true,
        sortable: false,
        width: '1%',
      },
      {
        text: 'Report Notes',
        value: 'reportNotes',
        sortable: false,
        width: '1%',
      },
      {
        text: 'Recent Correction',
        value: 'recentCorrection',
        sortable: false,
        width: '1%',
      },
      {
        text: 'Batch Number',
        value: 'batchNumber',
        readonly: this.isCapitalUserOrTrainee,
        sortable: false,
        width: '1%',
      },
    ];

    if (!this.isCapitalUserOrTrainee) {
      minimumHeaders.push({
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '1%',
        type: 'action',
      });
    }

    return minimumHeaders;
  }

  get nonEscrowHeaders(): DetailTableHeader[] {
    const minimumHeaders = [
      {
        text: 'Year',
        value: 'year',
        sortable: false,
        width: '1%',
        type: 'select',
        options: this.yearOptions,
      },
      {
        text: 'Base',
        value: 'base',
        sortable: false,
        width: '1%',
        type: 'currency',
      },
      {
        text: 'Payoff',
        value: 'amountDue',
        sortable: false,
        width: '1%',
        type: 'currency',
      },
      {
        text: 'Due By',
        value: 'dueDate',
        sortable: false,
        width: '1%',
        type: 'date',
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false,
        width: '1%',
        options: ['PAID', 'DELQ'],
      },
      {
        text: 'Notes',
        value: 'notes',
        sortable: false,
        width: '1%',
      },
      {
        text: 'Reported Date',
        value: 'reportedDate',
        readonly: this.isCapitalUserOrTrainee,
        sortable: false,
        width: '1%',
        type: 'date',
      },
      {
        text: 'Reported By',
        value: 'reportedBy',
        readonly: true,
        sortable: false,
        width: '1%',
      },
      {
        text: 'Updated Date',
        value: 'updatedOn',
        readonly: true,
        sortable: false,
        width: '1%',
        type: 'dateShort',
      },
      {
        text: 'Updated By',
        value: 'updatedBy',
        readonly: true,
        sortable: false,
        width: '1%',
      },
    ];

    if (!this.isCapitalUserOrTrainee) {
      minimumHeaders.push({
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '1%',
        type: 'action',
      });
    }

    return minimumHeaders;
  }

  private nonEscrowStatus: string = '';

  public clearBillingDialog: boolean = false;

  public noDataText = 'No data available';

  private agencySearch: string = '';
  private agencyDebounce: Function = null;
  private foundAgencies: Agency[] = [];

  public currentOptions: any = {};
  public currentSearch = '';
  public currentDebounce: Function = null;

  private noMask: any = {
    mask: '*'.repeat(255),
    tokens: {
      '*': { pattern: /./ },
    },
  };

  // Watchers
  @Watch('value', { immediate: true })
  onParcelChanged(parcel: Parcel) {
    this.determineSelectedAgency(parcel.agencies);
  }

  @Watch('editMode', { immediate: true })
  onEditModeChanged(editMode: boolean) {
    if (!this.selectedAgency) {
      return;
    }

    if (this.value.agencies.length > 0) {
      this.selectedAgencyIndex = this.value.agencies.findIndex((candidateAgency) => this.selectedAgency === candidateAgency);
    } else {
      this.selectedAgencyIndex = -1;
    }

    const {
      collectingYear, nonEscrowCollectingYear,
    } = this.selectedAgency;
    const escrowYearToCheck = DelinquentTaxCollectingYearUtil.collectingYearToYear(collectingYear as keyof typeof DelinquentTaxCollectingYear);
    const nonEscrowYearToCheck = DelinquentTaxCollectingYearUtil.collectingYearToYear(nonEscrowCollectingYear as keyof typeof DelinquentTaxCollectingYear);

    if (this.isTrainee) {
      if (this.value.parcelType !== 'N') {
        this.populateSelectedEscrowHistory(escrowYearToCheck);
      }

      this.populateSelectedNonEscrowHistory(nonEscrowYearToCheck);
    }
  }

  @Watch('selectedAgency', { immediate: true })
  onSelectedAgencyChanged(agency: ParcelAgency, oldAgency: ParcelAgency) {
    if ((oldAgency && agency) && (oldAgency.parcelAgencyId === agency.parcelAgencyId)) {
      return;
    }

    if (this.value.agencies.length > 0) {
      this.selectedAgencyIndex = this.value.agencies.findIndex((candidateAgency) => agency === candidateAgency);
    } else {
      this.selectedAgencyIndex = -1;
    }

    if (!this.selectedAgency) {
      return;
    }

    const {
      collectingYear,
    } = this.selectedAgency;
    const yearToCheck = DelinquentTaxCollectingYearUtil.collectingYearToYear(collectingYear as keyof typeof DelinquentTaxCollectingYear);

    this.populateSelectedEscrowHistory(yearToCheck);
  }

  @Watch('parcelValidation', { deep: true })
  onParcelValidationChanged(validation: Validation) {
    if (validation.$anyDirty) {
      // Only deverify the entity if a non-verified field was touched
      const verifyIsDirtied = this.$v.value.verified.$dirty;
      if (!verifyIsDirtied) {
        this.verifyEntity(false);
      }
    }
  }

  @Watch('addressValidation', { deep: true })
  onAddressValidationChanged(validation: Validation) {
    if (validation.$anyDirty) {
      this.verifyField(this.value.address, false, 'address');
    }
  }

  @Watch('mailingAddressValidation', { deep: true })
  onMailingAddressValidationChanged(validation: Validation) {
    if (validation.$anyDirty) {
      this.verifyField(this.value.mailingAddress, false, 'mailingAddress');
    }
  }

  // Computed
  get parcelValidation(): any {
    return this.$v.value;
  }

  get addressValidation(): any {
    return this.$v.value.address.value;
  }

  get mailingAddressValidation(): any {
    return this.$v.value.mailingAddress.value;
  }

  // Hooks
  async created() {
    const nextYear = (new Date()).getFullYear() + 1;

    for (let i = 0; i < 20; i += 1) {
      const relevantYear = nextYear - i;
      this.yearOptions.push(relevantYear.toString());
    }

    [, this.escrowCycleYear] = this.yearOptions;

    if (!this.selectedAgency) {
      this.selectedAgency = this.value.agencies.length > 0 ? this.value.agencies[0] : null;
    }

    this.searchForPossibleAgencies();
  }

  clearBillingData() {
    this.value.whenBilled = '';
    this.value.billedReport = '';
    this.changeField('whenBilled');
    this.changeField('billedReport');
  }

  generateEscrowCycle(year: string) {
    this.populateSelectedEscrowHistory(year);
  }

  populateSelectedEscrowHistory(year: string) {
    if (!this.selectedAgency || !this.editMode || !year) {
      return;
    }

    const {
      escrowHistory, collectingFrequency, agencyId, collectingSchedule, parcelAgencyId,
    } = this.selectedAgency;

    if (!collectingFrequency || !collectingSchedule) {
      console.log('No collecting frequency for this agency, cannot autogenerate.');
      return;
    }

    // Start with the existing
    const existingEscrowEntries = escrowHistory
      .filter((entry) => entry.year === year);

    // Determine the relevant collecting schedule entries
    // If there are matching lender entries, only use those
    // If not, use Capital entries
    let relevantEntries = collectingSchedule.filter(
      (collectingScheduleEntry) => collectingScheduleEntry.lenderId === this.value.lenderId,
    );

    if (relevantEntries.length === 0) {
      relevantEntries = collectingSchedule.filter(
        (collectingScheduleEntry) => !collectingScheduleEntry.lenderId,
      );
    }

    relevantEntries.forEach((collectingScheduleEntry) => {
      const foundEscrowEntries = existingEscrowEntries.filter((entry) => {
        if (collectingScheduleEntry.dueDate && collectingScheduleEntry.dueDate.value) {
          return collectingScheduleEntry.dueDate.value === entry.dueDate;
        }

        return false;
      });

      if (foundEscrowEntries.length > 0) {
        return;
      }

      this.selectedAgency.escrowHistory.push({
        agencyId,
        parcelAgencyId,
        year,
        term: collectingScheduleEntry.term,
        amountPaid: null,
        amountReported: null,
        zeroVerified: new Verified(null, false),
        reportNotes: '',
        recentCorrection: '',
        dueDate: collectingScheduleEntry.dueDate && collectingScheduleEntry.dueDate.value ? collectingScheduleEntry.dueDate.value : '',
        reportedDate: '',
        batchNumber: '',
        $auto: Boolean(collectingScheduleEntry.dueDate && collectingScheduleEntry.dueDate.value),
      });

      this.handleEscrowChange('add', this.selectedAgency.escrowHistory[this.selectedAgency.escrowHistory.length - 1]);
    });
  }

  populateSelectedNonEscrowHistory(year: string) {
    if (!this.selectedAgency || !this.editMode || !year) {
      return;
    }

    const {
      nonEscrowHistory, agencyId, parcelAgencyId,
    } = this.selectedAgency;

    // Start with the existing
    const existingEntries = nonEscrowHistory
      .filter((entry) => entry.year === year);

    if (existingEntries.length > 0) {
      return;
    }

    this.selectedAgency.nonEscrowHistory.push({
      agencyId,
      parcelAgencyId,
      year,
      base: null,
      amountDue: null,
      status: null,
      notes: '',
      priorYearStatus: null,
      dueDate: null,
      reportedDate: null,
      updatedOn: null,
    });
    this.handleNonEscrowChange('add', this.selectedAgency.nonEscrowHistory[this.selectedAgency.nonEscrowHistory.length - 1]);
  }

  changeField(key: string, op: string = 'replace', value: any = null, touch: boolean = true) {
    if (touch) {
      nestedFieldAccess(this.$v.value, key).$touch();
    }

    this.$emit('input', this.value);
    this.$emit('change', {
      key,
      value,
      op,
    });

    if (key === 'agencies') {
      this.calculateHasMatchingConfiguration();
      if (op === 'remove') {
        this.determineSelectedAgency(
          this.value.agencies.filter((pa) => pa.parcelAgencyId !== value.parcelAgencyId),
        );
      }
    } else if (key === 'parcelNumber') {
      this.calculateHasMatchingConfiguration();
    } else if (key === 'address.value.county' || key === 'address.value.state') {
      this.searchForPossibleAgencies();
    }
  }

  getEscrowValidation(key: string, index: number) {
    const validationObj = this.$v.value.agencies.$each[this.selectedAgencyIndex].escrowHistory.$each[index];

    if (!validationObj) {
      return null;
    }

    return validationObj[key];
  }

  handleEscrowChange(op: string, value: any) {
    if (op === 'add') {
      this.$v.value.agencies.$each[this.selectedAgencyIndex].escrowHistory.$touch();
    }

    const newValue = Object.assign(value, {
      $new: op === 'add',
      agencyId: this.selectedAgency.agencyId,
      parcelAgencyId: this.selectedAgency.parcelAgencyId,
    });

    this.changeField(
      `agencies.$each.${this.selectedAgencyIndex}.escrowHistory`,
      op,
      newValue,
    );
  }

  getDueDate(value: string) {
    const t = this.selectedAgency.collectingSchedule.find((s) => s.term === value);
    return t ? t.dueDate.value : null;
  }

  changeEscrowField(key: string, index: number, op: string = 'replace', value: any = 'null') {
    const keyToTouch = `agencies.$each.${this.selectedAgencyIndex}.escrowHistory.$each.${index}.${key}`;

    // If it does not exist, it's a new entry being worked on so ignore it
    if (!this.value.agencies[this.selectedAgencyIndex].escrowHistory[index]) {
      nestedFieldAccess(this.$v.value, keyToTouch).$touch();

      // When the term is changed, the newEntity is passed as the value and is updated here.
      if (key === 'term') {
        value.dueDate = this.getDueDate(value.term);
      }
      return;
    }

    if (key === 'term') {
      let dueDate = null;
      this.selectedAgency.collectingSchedule.forEach((s) => {
        if (s.term === this.value.agencies[this.selectedAgencyIndex].escrowHistory[index].term) {
          dueDate = s.dueDate.value;
        }
      });
      this.value.agencies[this.selectedAgencyIndex].escrowHistory[index].dueDate = dueDate;
      this.changeField(`agencies.$each.${this.selectedAgencyIndex}.escrowHistory.$each.${index}.dueDate`, 'replace', 'null');
    }

    this.changeField(keyToTouch, op, value);
  }

  getNonEscrowValidation(key: string, index: number) {
    if (!this.$v.value.agencies.$each[this.selectedAgencyIndex].nonEscrowHistory.$each[index]) {
      return null;
    }

    return this.$v.value.agencies.$each[this.selectedAgencyIndex].nonEscrowHistory.$each[index][key];
  }

  handleNonEscrowChange(op: string, value: any) {
    if (op === 'add') {
      this.$v.value.agencies.$each[this.selectedAgencyIndex].nonEscrowHistory.$touch();
    }

    const newValue = Object.assign(value, {
      $new: op === 'add',
      agencyId: this.selectedAgency.agencyId,
      parcelAgencyId: this.selectedAgency.parcelAgencyId,
      status: this.nonEscrowStatus,
    });

    this.changeField(
      `agencies.$each.${this.selectedAgencyIndex}.nonEscrowHistory`,
      op,
      newValue,
    );

    this.nonEscrowStatus = '';
  }

  changeNonEscrowStatusField(value: any, index: number) {
    if (!this.editMode) {
      return;
    }

    if (!this.value.agencies[this.selectedAgencyIndex].nonEscrowHistory[index]) {
      this.nonEscrowStatus = value;
      return;
    }

    if (this.value.agencies[this.selectedAgencyIndex].nonEscrowHistory[index].$new) {
      this.value.agencies[this.selectedAgencyIndex].nonEscrowHistory[index].status = value;
    } else {
      this.value.agencies[this.selectedAgencyIndex].nonEscrowHistory[index].status.value = value;
    }

    const fieldToChange = this.value.agencies[this.selectedAgencyIndex].nonEscrowHistory[index].$new
      ? `agencies.$each.${this.selectedAgencyIndex}.nonEscrowHistory.$each.${index}.status`
      : `agencies.$each.${this.selectedAgencyIndex}.nonEscrowHistory.$each.${index}.status.value`;

    this.changeField(fieldToChange, 'replace', value);
  }

  changeNonEscrowField(key: string, index: number, op: string = 'replace', value: any = 'null') {
    const keyToTouch = `agencies.$each.${this.selectedAgencyIndex}.nonEscrowHistory.$each.${index}.${key}`;

    // If it does not exist, it's a new entry being worked on so just ignore updates
    if (!this.value.agencies[this.selectedAgencyIndex].nonEscrowHistory[index]) {
      nestedFieldAccess(this.$v.value, keyToTouch).$touch();
      return;
    }

    this.changeField(keyToTouch, op, value);
  }

  changeParcelAgencyField(key: string, index: number, op: string = 'replace', value: any = 'null') {
    const keyToTouch = `agencies.$each.${index}.${key}`;

    this.changeField(keyToTouch, op, value);
  }

  verifyField(fieldToVerify: Verified, verified: boolean, key: string) {
    fieldToVerify.verified = verified;

    if (verified) {
      fieldToVerify.verifiedBy = this.user;
      fieldToVerify.verifiedOn = new Date();
    } else {
      fieldToVerify.verifiedBy = null;
      fieldToVerify.verifiedOn = null;
    }

    this.changeField(`${key}.verified`);
  }

  verifyEscrowField(index: number, fieldToVerify: Verified, verified: boolean, key: string) {
    const escrowKey = `agencies.$each.${this.selectedAgencyIndex}.escrowHistory.$each.${index}.${key}`;
    this.verifyField(fieldToVerify, verified, escrowKey);
  }

  verifyEntity(verified: boolean) {
    this.value.verified = verified;

    if (verified) {
      this.value.verifiedBy = this.user;
      this.value.verifiedOn = new Date();
    } else {
      this.value.verifiedBy = null;
      this.value.verifiedOn = null;
    }

    this.changeField('verified');
  }

  verifyParcelAgency(verified: boolean, index: number) {
    const parcelAgency = this.value.agencies[index];

    parcelAgency.parcelAgencyVerified.verified = verified;

    if (verified) {
      parcelAgency.parcelAgencyVerified.verifiedBy = this.user;
      parcelAgency.parcelAgencyVerified.verifiedOn = new Date();
    } else {
      parcelAgency.parcelAgencyVerified.verifiedBy = null;
      parcelAgency.parcelAgencyVerified.verifiedOn = null;
    }

    this.changeParcelAgencyField('parcelAgencyVerified', index, 'replace', verified);
  }

  singleConfigurationMatches(parcelId: any, configuration: any): boolean {
    const clean_configuration = configuration.trim().replace(/\( \)/g, ' ');
    if (parcelId && clean_configuration && parcelId.length === clean_configuration.length) {
      for (let i = 0; i < parcelId.length; i += 1) {
        if (clean_configuration[i] === 'N' && (parcelId[i] < '0' || parcelId[i] > '9')) {
          return false;
        }

        if (clean_configuration[i] === '@' && (parcelId[i] < '0' || parcelId[i] > '9') && (parcelId[i] < 'A' || parcelId[i] > 'Z') && (parcelId[i] < 'a' || parcelId[i] > 'z')) {
          return false;
        }

        if (clean_configuration[i] !== 'N' && clean_configuration[i] !== '@' && clean_configuration[i] !== parcelId[i]) {
          return false;
        }
      }
    } else {
      return false;
    }
    return true;
  }

  calculateHasMatchingConfiguration() {
    const originalValue = this.value.hasMatchingConfiguration;

    this.value.hasMatchingConfiguration = false;

    let agencyConfigurationsCount = 0;

    if (this.value.parcelNumber) {
      this.value.agencies.forEach((agency) => {
        agency.parcelConfigurations.forEach((configuration) => {
          agencyConfigurationsCount += 1;
          if (this.singleConfigurationMatches(this.value.parcelNumber.trim(), configuration.configuration.trim())) {
            this.value.hasMatchingConfiguration = true;
          }
        })
      });
    }

    if (agencyConfigurationsCount === 0) {
      this.value.hasMatchingConfiguration = true;
    }

    if (this.value.hasMatchingConfiguration !== originalValue) {
      this.changeField('hasMatchingConfiguration', 'replace', this.value.hasMatchingConfiguration);
    }
  }

  async searchForPossibleAgencies() {
    const params: any = {
      order_by: 'agencyCode',
      advanced_search: {},
    };

    if (this.value.address.value.county && this.value.address.value.county !== '') {
      params.advanced_search.county = this.value.address.value.county;
    } else {
      this.searchedAgencies = [];
      this.noDataText = 'County needed for search';
      return;
    }

    if (this.value.address.value.state && this.value.address.value.state !== '') {
      params.advanced_search.state = this.value.address.value.state;
    }

    if (this.currentOptions.sortBy && this.currentOptions.sortBy.length > 0) {
      [params.order_by] = this.currentOptions.sortBy;
      [params.order_by_desc] = this.currentOptions.sortDesc;
    }
    try {
      const results = await this.agencyService.getAllAgencies(params);
      this.searchedAgencies = results.results.map((agency) => ({ ...agency, displayString: `${agency.name} - ${agency.capAgency}` }));
    } catch (e) {
      console.log(e);
      this.searchedAgencies = [];
    }
  }

  @Watch('agencySearch')
  async onAgencySearchChanged(val: string) {
    if (!val || val.length <= 1) return;

    if (!this.agencyDebounce) {
      this.agencyDebounce = _.debounce(async () => {
        const response = await this.agencyService.getAllAgencies({
          search_field: 'name_or_number',
          search_value: this.agencySearch,
          limit: 100,
          // agency_selector_search: true,
        });

        this.foundAgencies = response.results;
      }, 500);
    }

    this.agencyDebounce();
  }

  determineSelectedAgency(agencies: ParcelAgency[]) {
    if (agencies.length === 0) {
      this.selectedAgency = null;
      return;
    }

    this.selectedAgency = agencies.find(
      (agency) => agency.parcelAgencyId === this.selectedAgency.parcelAgencyId,
    ) || agencies[0];
  }

  determineName(item: Agency): string {
    let nameString = item.name;

    if (item.address && item.address.value && item.address.value.county) {
      nameString += `, ${item.address.value.county}`;
    }

    if (item.address && item.address.value && item.address.value.state) {
      nameString += `, ${item.address.value.state}`;
    }

    nameString += `, ${item.capAgency}`;

    return nameString;
  }

  isNumber(value: number): boolean {
    return typeof value === 'number';
  }
}
