










import {
  Component,
  Emit,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { DataOptions } from 'vuetify';

import HistoryService from '@/services/history';

import Transaction from '@/entities/history/Transaction';

import HistoryList from '@/views/history/HistoryList.vue';

@Component({
  name: 'entity-history',
  components: {
    HistoryList,
  },
})
export default class EntityHistory extends Vue {
  @Prop({
    type: String,
    default: null,
  }) private readonly id!: string;

  private service: HistoryService = new HistoryService();
  private isLoading: boolean = false;
  private transactions: Transaction[] = [];
  private options: Partial<DataOptions> = {
    itemsPerPage: 250,
    page: 1,
  };

  private rollbackFailed: boolean = false;

  private activeEntry?: Transaction;

  // Table/iterator controls
  private itemsPerPage: number = 250;
  private totalItems: number = 0;

  @Watch('options', { deep: true })
  async onOptionsChanged(options: DataOptions, oldOptions: DataOptions) {
    if (oldOptions.page && (options.itemsPerPage !== oldOptions.itemsPerPage || options.page !== oldOptions.page)) {
      this.$emit('update:options', options);
    }
  }

  // Hooks
  async mounted() {
    this.fetchHistory();
  }

  async fetchHistory() {
    this.isLoading = true;

    const {
      page, itemsPerPage,
    } = this.options;

    console.log(this.options);

    const response = await this.service.getFullHistory({
      offset: (page - 1) * itemsPerPage,
      limit: itemsPerPage,
      entity_info: true,
      entity_ids: [this.id],
    });
    this.transactions = response.transactions;
    this.totalItems = response.count;

    this.isLoading = false;
  }

  @Emit('rollback')
  async triggerRollback(entry?: Transaction) {
    const targetEntry = entry || this.activeEntry;
    this.activeEntry = targetEntry;
    const response = await this.service.attemptRollback(entry);

    if (response && response.id) {
      this.rollbackFailed = false;
      const transaction = this.transactions.filter((h:any) => h.id === response.id).forEach((h: any) => {
        h.rolledBack = true;
      })
    } else {
      this.rollbackFailed = true;
    }

    return entry;
  }
}
