































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  Component,
  Prop,
  Emit,
  Mixins,
  Watch,
} from 'vue-property-decorator';
import {
  at, cloneDeep, debounce, get,
} from 'lodash';
import axios from 'axios';
import {
  required, integer, email,
} from 'vuelidate/lib/validators';
import { validationMixin, Validation } from 'vuelidate';
import { DateTime } from 'luxon';
import { State, Action } from 'vuex-class';

import Agency from '@/entities/Agency';
import IclOclType from '@/entities/IclOclType';
import {
  DelinquentTaxCollectingYear, ICollectingEntry, ICollectingSchedule, IDelinquentTaxCollectingOffice, IRelatedAgency,
} from '@/entities/IAgency';
import TaxCollectingFrequency from '@/entities/TaxCollectingFrequency';
import TaxCollectingFrequencyUtil from '@/entities/TaxCollectingFrequencyUtil';
import Term, { TermUtil } from '@/entities/Term';
import Verified from '@/entities/Verified';
import Lender from '@/entities/Lender';
import IFile from '@/entities/IFile';

import AgencyService from '@/services/agencies';
import ContactTypesService from '@/services/contactTypes';

import VerifiedCheckbox from '@/components/VerifiedCheckbox.vue';
import EntityHistory from '@/views/history/EntityHistory.vue';

import {
  phoneNumber, usZipCode, permissiveUrl, shortDate, stateTerritoryAbbr,
} from '@/validations';
import { fileSizeValidator } from '@/validations/vuetify';
import ValidationErrors from '@/mixins/ValidationErrors.vue';
import PreventDirtyLeave from '@/mixins/PreventDirtyLeave.vue';
import UserPermissions from '@/mixins/UserPermissions.vue';
import CurrencyFormatter from '@/mixins/CurrencyFormatter.vue';
import GlobalNotifications from '@/mixins/GlobalNotifications.vue';

import vuelidateToPatch, {
  JsonPatchPayload, customAddressConverter, simpleVerifiedConverter, JsonPatchOperator, JsonPatchEntry, currencyFieldConverter,
} from '@/helpers/vuelidateToPatch';

const validations: any = {
  agency: {
    name: { required },
    capAgency: {
      required,
    },
    address: {
      value: {
        address1: {},
        address2: {},
        city: {},
        state: { stateTerritoryAbbr },
        zipCode: { usZipCode },
        county: {},
      },
      verified: {},
    },
    taxType: {},
    postMarkIndicator: {},
    duplicateBillFee: {},
    duplicateBillAmount: {},
    cooperationIndicator: {},
    website: { permissiveUrl },
    websiteNotes: {},
    dueDateNotes: {},
    auditStartDate: { shortDate },
    auditNotes: {},
    taxPayableTo: {},
    phoneNumber: { phoneNumber },
    taxBillCollection: {},
    payableTo: {},
    billsMailed: {},
    countyLines: {},
    countyLinesNotes: {},
    parcelCoding: {},
    agencyLenders: {},
    capitalNotes: {},
    nonEscrowCollectingYear: {},
    collectingYear: {},
    collectingFrequency: {},
    dataSystemUsed: {},
    publicTerminalsAvailable: { integer },
    currentTaxWebsite: { permissiveUrl },
    priorTaxWebsite: { permissiveUrl },
    delinquentTaxWebsite: { permissiveUrl },
    delinquentTaxCollectingId: {},
    delinquentCollectingWebsiteNotes: {},
    collectorPhone: {},
    assessorPhone: {},
    fullTaxFileAvailable: {},
    fullTaxFileAvailableAmount: {},
    electronicFile: {},
    electronicFileAmount: {},
    listingRequiredWithPayment: {},
    usesThirdParty: {},
    discountedBill: {},
    originalBill: {},
    originalListingRequired: {},
    payableToAddress: {
      value: {
        address1: {},
        address2: {},
        city: {},
        state: { stateTerritoryAbbr },
        zipCode: { usZipCode },
        county: {},
      },
      verified: {},
    },

    delinquentTaxCollectingOffice: {},

    collectingSchedule: {
      $each: {
        $new: {},
        term: {},
        billRequestDate: { shortDate },
        billReleaseDate: { shortDate },
        dueDate: {
          value: { shortDate },
          verified: {},
        },
        taxBillCheckIn: {
          value: {},
          verified: {},
        },
        lastDayToPay: { shortDate },
        lenderId: {},
      },
    },

    contacts: {
      $each: {
        $new: {},
        type: {},
        name: {},
        phone: { phoneNumber },
        extension: {},
        fax: { phoneNumber },
        email: { email },
        address: {},
        notes: {},
        hours: {},
      },
    },

    taxProcessors: {
      $each: {
        $new: {},
        taxOffice: {},
        agencyCode: {},
        type: {},
        contact: {},
        phone: { phoneNumber },
        fax: { phoneNumber },
        email: { email },
        mortgageCode: {},
        notes: {},
        address: {},
        mailing1: {},
        mailing2: {},
      },
    },

    crossReferenceCodes: {
      $each: {
        lenderNumber: {},
        lenderName: {},
        crossReferenceCode: {},
      },
    },

    agencyVerified: {},
  },
};

@Component({
  name: 'agency-detail',
  validations,
  components: {
    VerifiedCheckbox,
    EntityHistory,
  },
  mixins: [validationMixin],
})
export default class AgenciesDetail extends Mixins(UserPermissions, GlobalNotifications, ValidationErrors, PreventDirtyLeave, CurrencyFormatter) {
  [key: string]: any;

  @State((state) => state.lenders.lenders) lenders!: Lender[];
  @Action('fetchLenders') fetchLenders!: () => void;

  @Prop({
    type: String,
  }) private readonly agencyId!: string;

  @Prop({
    type: String,
  }) private readonly view!: string;

  @Prop({
    type: Number,
    default: 1,
  }) private readonly fontSize!: number;

  private agencyService: AgencyService = new AgencyService();
  private collectingYearOptions = Object.keys(DelinquentTaxCollectingYear).filter((k) => typeof DelinquentTaxCollectingYear[k as keyof typeof DelinquentTaxCollectingYear] === 'string').map((k) => ({
    text: DelinquentTaxCollectingYear[k as keyof typeof DelinquentTaxCollectingYear],
    value: k,
  }));

  private collectingFrequencyOptions = Object.keys(TaxCollectingFrequency).filter((k) => typeof TaxCollectingFrequency[k as keyof typeof TaxCollectingFrequency] === 'string').map((k) => ({
    text: TaxCollectingFrequency[k as keyof typeof TaxCollectingFrequency],
    value: k,
  }));

  private countyLineOptions = Object.keys(IclOclType).filter((k) => typeof IclOclType[k as keyof typeof IclOclType] === 'string').map((k) => ({
    text: IclOclType[k as keyof typeof IclOclType],
    value: k,
  }));

  private termOptions = Object.keys(Term).filter((k) => typeof Term[k as keyof typeof Term] === 'string').map((k) => ({
    text: Term[k as keyof typeof Term],
    value: k,
  }));

  private trueFalseOptions = [
    {
      text: 'Yes',
      value: true,
    },
    {
      text: 'No',
      value: false,
    },
  ];

  private originalBillOptions = [
    {
      text: 'Yes',
      value: 'YES',
    },
    {
      text: 'No',
      value: 'NO',
    },
    {
      text: 'Preferred',
      value: 'PREFERRED',
    },
  ];

  private patchError: any = null;
  private confirmingDeletion = false;
  private showingHistory = false;
  private editMode: boolean = false;
  private loading: boolean = false;
  private updating: boolean = false;
  private hadSchedule: boolean = false;
  private contactSearch: string = '';
  private processorSearch: string = '';

  private files: File[] = [];
  private fileRules: Function[] = [
    fileSizeValidator(15000000, 'File size limit is 15 MB'),
  ];
  private fileValid: boolean = false;

  // List controls
  private addingNewContact: boolean = false;
  private addingNewProcessor: boolean = false;
  private addingNewConfiguration: boolean = false;
  private addingNewRelatedTaxOffice: boolean = false;
  private addingNewCrossReferenceCode: boolean = false;
  private addingNewScheduleEntry: boolean = false;

  // Related agencies
  private newRelatedAgencies: IRelatedAgency[] = [];
  private removedRelatedAgencies: IRelatedAgency[] = [];
  private agencySearch: string = '';
  private agencyDebounce: Function = null;
  private foundAgencies: Agency[] = [];
  private selectedAgency: Agency = null;

  // DTCO
  private dtcoSearch: string = '';
  private dtcoDebounce: Function = null;
  private foundDtcos: (Agency | IDelinquentTaxCollectingOffice)[] = [];

  // Collecting schedule
  private newSchedules: ICollectingEntry[] = [];
  private removedSchedules: ICollectingEntry[] = [];

  // Configurations
  private newConfigurations: any[] = [];
  private removedConfigurations: any[] = [];

  // Contacts
  private newContacts: any[] = [];
  private removedContacts: any[] = [];

  // Tax processors
  private newProcessors: any[] = [];
  private removedProcessors: any[] = [];

  // Cross Reference Code
  private crossReferenceCodeSearch: string = '';
  private newCrossReferenceCodes: any[] = [];
  private removedCrossReferenceCodes: any[] = [];

  private noMask: any = {
    mask: '*'.repeat(255),
    tokens: {
      '*': { pattern: /./ },
    },
  };

  private service: AgencyService = new AgencyService();
  private contactTypesService: ContactTypesService = new ContactTypesService();

  private agency: Agency = null;

  private contactTypes: any = []; // JSON.parse(localStorage.contactTypes);
  private url: string = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/`;

  private collectingScheduleHeaders: any[] = [
    {
      text: 'Lender',
      value: 'lenderId',
      sortable: false,
      width: '1%',
      type: 'lenders',
    },
    {
      text: 'Term',
      value: 'term',
      sortable: false,
      width: '1%',
      type: 'select',
      options: this.termOptions,
    },
    {
      text: 'Bill Request Date',
      value: 'billRequestDate',
      sortable: false,
      width: '1%',
      type: 'date',
    },
    {
      text: 'Bill Release Date',
      value: 'billReleaseDate',
      sortable: false,
      width: '1%',
      type: 'date',
    },
    {
      text: 'Due Date',
      value: 'dueDate.value',
      sortable: false,
      width: '1%',
      type: 'date',
    },
    {
      text: 'Last Day to Pay',
      value: 'lastDayToPay',
      sortable: false,
      width: '1%',
      type: 'date',
    },
    {
      text: 'Due Date Verified',
      value: 'dueDate',
      sortable: false,
      width: '1%',
      type: 'verified',
    },
    {
      text: 'Tax Bill Check In',
      value: 'taxBillCheckIn',
      sortable: false,
      width: '1%',
      type: 'verified',
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      width: '1%',
      type: 'action',
    },
  ];

  private contactHeaders: any[] = [
    {
      text: 'Type',
      value: 'type',
      sortable: true,
      width: '12%',
      type: 'chip',
    },
    {
      text: 'Name',
      value: 'name',
      sortable: false,
      width: '12%',
    },
    {
      text: 'Phone',
      value: 'phone',
      sortable: false,
      width: '12%',
      type: 'phone',
    },
    {
      text: 'Ext',
      value: 'extension',
      sortable: false,
      width: '5%',
    },
    {
      text: 'Fax',
      value: 'fax',
      sortable: false,
      width: '10%',
      type: 'phone',
    },
    {
      text: 'Email',
      value: 'email',
      sortable: false,
      width: '15%',
      type: 'email',
    },
    {
      text: 'Notes',
      value: 'notes',
      sortable: false,
      width: '10%',
    },
    {
      text: 'Hours',
      value: 'hours',
      sortable: false,
      width: '5%',
    },
    {
      text: '',
      value: 'controls',
      sortable: false,
      width: '1%',
      type: 'action',
    },
  ];

  private dataProcessorInformationHeaders: any[] = [
    {
      text: 'Type',
      value: 'type',
      sortable: false,
      width: '1%',
      type: 'chip',
    },
    {
      text: 'Contact',
      value: 'contact',
      sortable: false,
      width: '1%',
    },
    {
      text: 'Phone',
      value: 'phone',
      sortable: false,
      width: '3%',
      type: 'phone',
    },
    {
      text: 'Fax',
      value: 'fax',
      sortable: false,
      width: '3%',
      type: 'phone',
    },
    {
      text: 'Email',
      value: 'email',
      sortable: false,
      width: '3%',
      type: 'email',
    },
    {
      text: 'Mortgage Code',
      value: 'mortgageCode',
      sortable: false,
      width: '3%',
    },
    {
      text: 'Notes',
      value: 'notes',
      sortable: false,
      width: '1%',
    },
    {
      text: 'Street Address',
      value: 'mailing1',
      sortable: false,
      width: '5%',
    },
    {
      text: 'City, ST ZIP',
      value: 'mailing2',
      sortable: false,
      width: '5%',
    },
    {
      text: '',
      value: 'controls',
      sortable: false,
      width: '1%',
      type: 'action',
    },
  ];

  private crossReferenceCodeHeaders: any[] = [
    {
      text: 'Lender Number',
      value: 'lenderNumber',
      sortable: false,
      width: '10%',
    },
    {
      text: 'Lender Name',
      value: 'lenderName',
      sortable: false,
      width: '10%',
    },
    {
      text: 'Code',
      value: 'crossReferenceCode',
      sortable: false,
      width: '10%',
    },
    {
      text: '',
      value: 'controls',
      sortable: false,
      width: '1%',
    },
  ];

  // Watchers
  @Watch('editMode')
  onValueChanged(editMode: boolean) {
    if (editMode && this.agency && this.agency.collectingSchedule.length === 0) {
      const frequency = this.agency.collectingFrequency || 'annually';
      const defaultDates = TaxCollectingFrequencyUtil.generateDates(frequency as keyof typeof TaxCollectingFrequency);
      const terms = TermUtil.getTermKeysForFrequency(frequency as keyof typeof TaxCollectingFrequency);

      this.newSchedules = defaultDates.map((date, index) => {
        const scheduleShortDate = DateTime.fromJSDate(date).toFormat('MM/dd');
        return {
          $new: true,
          agencyId: this.agency.agencyId,
          term: terms[index],
          billRequestDate: scheduleShortDate,
          billReleaseDate: scheduleShortDate,
          taxBillCheckIn: new Verified(undefined, false),
          lastDayToPay: scheduleShortDate,
          dueDate: new Verified(null, false),
          lenderId: null,
          index,
        };
      });

      this.agency.collectingSchedule = this.newSchedules.map((entry) => entry);
    }
  }

  @Watch('agencySearch')
  async onAgencySearchChanged(val: string) {
    if (!val || val.length <= 1) return;

    if (!this.agencyDebounce) {
      this.agencyDebounce = debounce(async () => {
        this.agencyService.getAllAgencies({
          search_field: 'name_or_number',
          search_value: this.agencySearch,
          limit: 100,
          agency_selector_search: true,
        }).then((response) => {
          this.foundAgencies = response.results;
        });
      }, 500);
    }

    this.agencyDebounce();
  }

  @Watch('dtcoSearch')
  async onDtcoSearchChanged(val: string) {
    if (!val || val.length <= 1) return;

    const selectedDtco = this.foundDtcos.find((x) => x.agencyId === this.agency.delinquentTaxCollectingId);
    this.foundDtcos = selectedDtco ? [selectedDtco] : [];
    if (!this.dtcoDebounce) {
      this.dtcoDebounce = debounce(async () => {
        this.agencyService.getAllAgencies({
          search_field: 'name_or_number',
          search_value: this.dtcoSearch,
          limit: 100,
          agency_selector_search: true,
        }).then((response) => {
          this.foundDtcos.unshift(...response.results);
        });
      }, 500);
    }

    this.dtcoDebounce();
  }

  @Watch('agencyValidation', { deep: true })
  onAgencyValidationChanged(validation: Validation) {
    if (validation.$anyDirty) {
      // Only deverify the entity if a non-verified field was touched
      const verifyIsDirtied = this.$v.agency.agencyVerified.$dirty;
      if (!verifyIsDirtied) {
        this.verifyEntity(false);
      }
    }
  }

  @Watch('addressValidation', { deep: true })
  onAddressValidationChanged(validation: Validation) {
    if (validation.$anyDirty) {
      this.verifyField(this.agency.address, false, 'address');
    }
  }

  @Watch('payableToAddressValidation', { deep: true })
  onMailingAddressValidationChanged(validation: Validation) {
    if (validation.$anyDirty) {
      this.verifyField(this.agency.payableToAddress, false, 'payableToAddress');
    }
  }

  async created() {
    this.editMode = this.$route && this.$route.query.editMode === 'true';
    await this.getAgency();

    if (this.agency.delinquentTaxCollectingOffice) {
      this.foundDtcos.push(this.agency.delinquentTaxCollectingOffice);
    }

    const contactTypeResponse = await this.contactTypesService.getContactTypes({ search_entity: 'agencies' });
    this.contactTypes = contactTypeResponse.types.map((type) => type.name);

    // Autogenerate the schedule if we enter from creation
    if (this.editMode) {
      this.onValueChanged(this.editMode);
    }

    this.fetchLenders();
  }

  // Computed
  get dirtyFields(): string[] {
    return Object.keys(this.$v.agency.$params)
      .filter((fieldName) => this.$v.agency[fieldName].$anyDirty);
  }

  get invalidFields(): string[] {
    return Object.keys(this.$v.agency.$params)
      .filter((fieldName) => this.$v.agency[fieldName].$invalid);
  }

  get nestedInvalidFields(): string [] {
    const leafParams = this.$v.agency.$flattenParams();
    const invalidFields: string[] = [];

    leafParams.forEach((param) => {
      const path = param.path.join('.');
      if (at(this.$v.agency, path)[0].$invalid) {
        invalidFields.push(path);
      }
    });

    return invalidFields;
  }

  get options() {
    return {
      locale: 'en',
      currency: 'USD',
      precision: 2,
    };
  }

  get agencyValidation(): any {
    return this.$v.agency;
  }

  get addressValidation(): any {
    return this.$v.agency.address.value;
  }

  get payableToAddressValidation(): any {
    return this.$v.agency.payableToAddress.value;
  }

  async getAgency(quiet: boolean = false, field: keyof Agency = null) {
    try {
      this.loading = !quiet;
      const agency = await this.service.getAgency(this.agencyId || this.$route.params.id);

      if (field) {
        this.agency[field] = agency[field];
        return;
      }

      this.agency = agency;

      this.hadSchedule = (this.agency.collectingSchedule && (this.agency.collectingSchedule.length > 0));
      this.agency.relatedAgencies = Array.from(
        new Set(this.agency.relatedAgencies.filter((i) => i)),
      );

      this.agency.contacts.forEach((c, i) => {
        c.index = i;
      });

      this.agency.taxProcessors.forEach((d, i) => {
        d.index = i;
      });

      this.agency.parcelConfigurations.forEach((c, i) => {
        c.index = i;
      })

      this.agency.crossReferenceCodes.forEach((p, i) => {
        p.index = i;
      });

      this.agency.collectingSchedule.forEach((e, i) => {
        e.index = i;
      });

      this.loading = false;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    } catch (e) {
      console.log(e);
    }
  }

  async updateAgency(): Promise<boolean> {
    if (!this.$v.agency) {
      console.log('No validation.');
      return true;
    }

    const dirtyInvalidFields = this.nestedInvalidFields
      .filter((field) => at(this.$v.agency, field)[0].$dirty);

    const cleanInvalidFields = this.nestedInvalidFields
      .filter((field) => !at(this.$v.agency, field)[0].$dirty);

    if (this.$v.agency.$invalid) {
      // Do not allow update to continue if a dirtied field is invalid
      if (dirtyInvalidFields.length > 0) {
        console.error('Fields were invalid. Cancelling update.', dirtyInvalidFields);

        // Touch all invalid fields
        cleanInvalidFields.forEach((field) => this.$v.agency[field].$touch());
        this.showError({
          text: 'Could not save changes.  One or more form fields are invalid.  Please correct and try again.',
          timeout: 5000,
          closeable: true,
        });
        return false;
      }
    }

    try {
      const payload: JsonPatchPayload = vuelidateToPatch(this.$v.agency, [{
        key: 'address',
        converter: customAddressConverter({ address1: 'street1', address2: 'street2' }),
      }, {
        key: 'payableToAddress',
        converter: customAddressConverter({ address1: 'address', zipCode: 'zip' }, 'payable_to'),
      }, {
        key: 'fullTaxFileAvailableAmount',
        converter: (value: any): JsonPatchEntry[] => currencyFieldConverter(value, 'fullTaxFileAvailableAmount'),
      }, {
        key: 'electronicFileAmount',
        converter: (value: any): JsonPatchEntry[] => currencyFieldConverter(value, 'electronicFileAmount'),
      }, {
        key: 'duplicateBillAmount',
        converter: (value: any): JsonPatchEntry[] => currencyFieldConverter(value, 'duplicateBillAmount'),
      }], {
        contacts: 'agencyContactId',
        taxProcessors: 'agencyTaxProcessorId',
        crossReferenceCodes: 'agencyCrossReferenceCodeId',
        collectingSchedule: {
          key: 'agencyCollectingScheduleId',
          converters: [{
            key: 'dueDate',
            converter: (value: any): JsonPatchEntry[] => {
              const patchEntries: JsonPatchEntry[] = [];

              if (value.verified && value.verified.$dirty) {
                patchEntries.push({
                  op: JsonPatchOperator.replace,
                  path: '/due_date_verified',
                  value: (value.$model.verified && value.$model.verified.id) || value.$model.verified,
                });
              }

              if (value.value && value.value.$dirty) {
                patchEntries.push({
                  op: JsonPatchOperator.replace,
                  path: '/due_date',
                  value: (value.$model.value && value.$model.value.id) || value.$model.value,
                });
              }

              return patchEntries;
            },
          }, {
            key: 'taxBillCheckIn',
            converter: (value: any): JsonPatchEntry[] => simpleVerifiedConverter(value, 'taxBillCheckIn'),
          }, {
            key: 'lenderId',
            converter: (value: any): JsonPatchEntry[] => {
              const patchEntries: JsonPatchEntry[] = [];

              patchEntries.push({
                op: JsonPatchOperator.replace,
                path: '/lender_id',
                value: value.$model === undefined ? null : value.$model,
              });

              return patchEntries;
            },
          }],
        },
      });

      if (this.newSchedules.length > 0) {
        this.newSchedules.forEach((schedule) => {
          delete schedule.index;
          delete schedule.$new;
          payload.push({
            op: JsonPatchOperator.add,
            path: '/collecting_schedule/-',
            value: {
              term: schedule.term,
              bill_request_date: schedule.billRequestDate,
              bill_release_date: schedule.billReleaseDate,
              last_day_to_pay: schedule.lastDayToPay,
              due_date: schedule.dueDate ? schedule.dueDate.value : null,
              due_date_verified: schedule.dueDate ? schedule.dueDate.verified : false,
              tax_bill_check_in: schedule.taxBillCheckIn.verified,
              lender_id: schedule.lenderId,
            },
          });
        });
      }

      if (this.removedSchedules.length > 0) {
        this.removedSchedules.filter((schedule) => Boolean(schedule.agencyCollectingScheduleId)).forEach((schedule) => {
          payload.push({
            op: JsonPatchOperator.remove,
            path: `/collecting_schedule/${schedule.agencyCollectingScheduleId}`,
          });
        });
      }

      if (this.newConfigurations.length > 0) {
        this.newConfigurations.forEach((configuration) => {
          delete configuration.$pending;
          delete configuration.$new;
          delete configuration.index;
          delete configuration.dialog;
          payload.push({
            op: JsonPatchOperator.add,
            path: '/parcel_configurations/-',
            value: configuration.configuration,
          });
        });
      }

      if (this.removedConfigurations.length > 0) {
        this.removedConfigurations.filter((config) => Boolean(config.agencyConfigurationId)).forEach((configuration) => {
          payload.push({
            op: JsonPatchOperator.remove,
            path: `/parcel_configurations/${configuration.agencyConfigurationId}`,
          });
        });
      }

      if (this.newContacts.length > 0) {
        this.newContacts.forEach((contact) => {
          delete contact.$pending;
          delete contact.$new;
          delete contact.index;
          delete contact.dialog;
          payload.push({
            op: JsonPatchOperator.add,
            path: '/contacts/-',
            value: contact,
          });
        });
      }

      if (this.removedContacts.length > 0) {
        this.removedContacts.filter((contact) => Boolean(contact.agencyContactId)).forEach((contact) => {
          payload.push({
            op: JsonPatchOperator.remove,
            path: `/contacts/${contact.agencyContactId}`,
          });
        });
      }

      if (this.newProcessors.length > 0) {
        this.newProcessors.forEach((processor) => {
          delete processor.$pending;
          delete processor.$new;
          delete processor.index;
          delete processor.dialog;
          processor.mortgagecode = processor.mortgageCode;
          delete processor.mortgageCode;
          payload.push({
            op: JsonPatchOperator.add,
            path: '/tax_processors/-',
            value: processor,
          });
        });
      }

      if (this.removedProcessors.length > 0) {
        this.removedProcessors.filter((processor) => Boolean(processor.agencyTaxProcessorId)).forEach((processor) => {
          payload.push({
            op: JsonPatchOperator.remove,
            path: `/tax_processors/${processor.agencyTaxProcessorId}`,
          });
        });
      }

      if (this.newRelatedAgencies.length > 0) {
        this.newRelatedAgencies.forEach((relatedAgency) => {
          delete relatedAgency.$pending;
          delete relatedAgency.$new;
          delete relatedAgency.index;
          delete relatedAgency.dialog;

          payload.push({
            op: JsonPatchOperator.add,
            path: '/related_agencies/-',
            value: relatedAgency.agencyId,
          });
        });
      }

      if (this.removedRelatedAgencies.length > 0) {
        this.removedRelatedAgencies.filter((relatedAgency) => Boolean(relatedAgency.relatedAgencyId)).forEach((relatedAgency) => {
          payload.push({
            op: JsonPatchOperator.remove,
            path: `/related_agencies/${relatedAgency.relatedAgencyId}`,
          });
        });
      }

      if (this.newCrossReferenceCodes.length > 0) {
        this.newCrossReferenceCodes.forEach((code) => {
          const actualCode = {
            lender_name: code.lenderName,
            lender_number: code.lenderNumber,
            cross_reference_code: code.crossReferenceCode,
          };

          payload.push({
            op: JsonPatchOperator.add,
            path: '/cross_reference_codes/-',
            value: actualCode,
          });
        });
      }

      if (this.removedCrossReferenceCodes.length > 0) {
        this.removedCrossReferenceCodes.filter((code) => Boolean(code.agencyCrossReferenceCodeId)).forEach((code) => {
          const actualCode = { ...code };
          payload.push({
            op: JsonPatchOperator.remove,
            path: `/cross_reference_codes/${code.agencyCrossReferenceCodeId}`,
          });
        });
      }

      if (payload.length === 0) {
        // TODO: Renotify that no changes were made?
        return true;
      }

      this.updating = true;
      await this.service.updateAgency(this.$route.params.id, payload);

      this.$v.$reset();

      // Touch all untouched invalid fields
      cleanInvalidFields.forEach((field) => at(this.$v.agency, field)[0].$touch());

      // Reset all of the various subentity trackers
      this.newSchedules = [];
      this.removedSchedules = [];
      this.newConfigurations = [];
      this.removedConfigurations = [];
      this.newContacts = [];
      this.removedContacts = [];
      this.newProcessors = [];
      this.removedProcessors = [];
      this.newRelatedAgencies = [];
      this.removedRelatedAgencies = [];
      this.newCrossReferenceCodes = [];
      this.removedCrossReferenceCodes = [];

      this.$forceUpdate();

      this.getAgency(true);
    } catch (e) {
      if (e.response.status === 412) {
        this.showError({
          text: 'This agency has been modified by another user. Please refresh to make changes.',
          actions: {
            text: 'Refresh',
            function: () => { document.location.reload() },
          },
        });
      } else {
        this.showError({
          text: 'Could not save changes.  There was an issue with the database.',
          timeout: 10000,
          closeable: true,
          actions: {
            text: 'Copy',
            function: this.copyError,
          },
        });
        this.patchError = e.response && e.response.data && e.response.data.detail;
      }

      this.updating = false;
      return false;
    }

    this.updating = false;
    return true;
  }

  private copyError() {
    const errorSummary = {
      error: this.patchError,
      form: {
        dirty: this.dirtyFields,
        invalid: this.nestedInvalidFields,
      },
    };

    this.$copyText(JSON.stringify(errorSummary));
  }

  resolveLink(link: string): string {
    if (!link) {
      return null;
    }

    return link.includes('://') ? link : `http://${link}`;
  }

  resolveAndOpenLink(link?: string, windowName?: string): boolean {
    if (!link) {
      return true;
    }

    const resolvedLink = this.resolveLink(link);
    window.open(resolvedLink, windowName, 'width=1024,height=768');

    return false;
  }

  async editToggle() {
    let shouldToggle = true;

    if (this.editMode) {
      // Clear all pending new subentities
      const flags: string[] = ['addingNewContact', 'addingNewProcessor', 'addingNewConfiguration', 'addingNewRelatedTaxOffice', 'addingNewCrossReferenceCode', 'addingNewScheduleEntry'];
      const subentityArrays: string[] = ['contacts', 'taxProcessors', 'crossReferenceCodes', 'collectingSchedule', 'parcelConfigurations', 'relatedAgencies'];
      flags.forEach((flag) => {
        this[flag] = false;
      });

      subentityArrays.forEach((array) => {
        this.agency[array] = (this.agency[array] as { $pending: boolean }[]).filter((entry) => !entry.$pending);
      });

      shouldToggle = await this.updateAgency();
    }

    if (shouldToggle) {
      this.editMode = !this.editMode;
    }
  }

  getContactTypeColor(item: any) {
    const contactTypesList = JSON.parse(localStorage.contactTypes || '{}');

    if (contactTypesList[item.type]) {
      return contactTypesList[item.type];
    }

    return '#cccccc;';
  }

  verifyField(fieldToVerify: Verified, verified: boolean, key: string) {
    function nestedFieldAccess(obj: any, objKey: string) {
      objKey = objKey.replace(/\[(\w+)\]/g, '.$1');
      objKey = objKey.replace(/^\./, '');
      const a = objKey.split('.');
      for (let i = 0, n = a.length; i < n; i += 1) {
        const k = a[i];
        if (k in obj) {
          obj = obj[k];
        } else {
          return obj;
        }
      }
      return obj;
    }

    fieldToVerify.verified = verified;

    if (verified) {
      fieldToVerify.verifiedBy = this.user;
      fieldToVerify.verifiedOn = new Date();
    } else {
      fieldToVerify.verifiedBy = null;
      fieldToVerify.verifiedOn = null;
    }

    nestedFieldAccess(this.$v.agency, key).verified.$touch();
  }

  verifyEntity(verified: boolean) {
    this.agency.agencyVerified = verified;

    if (verified) {
      this.agency.agencyVerifiedBy = this.user;
      this.agency.agencyVerifiedOn = new Date();
    } else {
      this.agency.agencyVerifiedBy = null;
      this.agency.agencyVerifiedOn = null;
    }

    this.$v.agency.agencyVerified.$touch();
  }

  changeField(key: string) {
    get(this.$v.agency, key).$touch();
  }

  changeCollectingScheduleField(key: string, index: number) {
    const keyToTouch = `collectingSchedule.$each.${index}.${key}`;

    if (key === 'dueDate.value') {
      this.agency.collectingSchedule[index].dueDate.verified = false;
      this.$v.agency.collectingSchedule.$each[index].dueDate.verified.$touch();
    }

    this.changeField(keyToTouch);
  }

  uploadFiles() {
    return Promise.all(
      this.files.map((file) => this.service.uploadFile(this.agency.agencyId, file)),
    ).then(() => {
      this.files = [];
      this.getAgency(true, 'files');
    });
  }

  deleteFile(file: IFile) {
    axios.delete(file.url)
      .then(() => {
        this.agency.files = this.agency.files.filter((oldFile) => oldFile !== file);
      });
  }

  addNewContact() {
    this.addingNewContact = !this.addingNewContact;
    this.agency.contacts.push({
      $pending: true,
      $new: true,
      index: this.agency.contacts.length,
    });
  }

  saveNewContact() {
    this.addingNewContact = !this.addingNewContact;
    const contactEntry = this.agency.contacts[this.agency.contacts.length - 1];
    contactEntry.$pending = false;

    this.newContacts.push(contactEntry);
  }

  clearNewContact() {
    this.addingNewContact = !this.addingNewContact;
    this.agency.contacts.pop();
  }

  removeContact(contact: any) {
    if (contact.$new) {
      this.newContacts.splice(this.newContacts.find((c) => c.index === contact.index), 1);
    } else {
      this.removedContacts.push(contact);
    }
    this.agency.contacts.splice(contact.index, 1);
    this.agency.contacts.forEach((c, i) => {
      c.index = i;
    });
  }

  addNewProcessor() {
    this.addingNewProcessor = !this.addingNewProcessor;
    this.agency.taxProcessors.push({
      $pending: true,
      $new: true,
      index: this.agency.taxProcessors.length,
    });
  }

  saveNewProcessor() {
    this.addingNewProcessor = !this.addingNewProcessor;
    const taxProcessor = this.agency.taxProcessors[this.agency.taxProcessors.length - 1];
    taxProcessor.$pending = false;

    this.newProcessors.push(taxProcessor);
  }

  clearNewProcessor() {
    this.addingNewProcessor = !this.addingNewProcessor;
    this.agency.taxProcessors.pop();
  }

  removeProcessor(processor: any) {
    if (processor.$new) {
      this.newProcessors.splice(this.newProcessors.find((p) => p.index === processor.index), 1);
    } else {
      this.removedProcessors.push(processor);
    }
    this.agency.taxProcessors.splice(processor.index, 1);
    this.agency.taxProcessors.forEach((p, i) => {
      p.index = i;
    });
  }

  updateCrossReferenceCode(codeToUpdate: any) {
    if (!codeToUpdate.agencyCrossReferenceCodeId) return;

    const updateIndex = this.agency.crossReferenceCodes.findIndex(
      (code) => code.agencyCrossReferenceCodeId === codeToUpdate.agencyCrossReferenceCodeId,
    );

    this.$v.agency.crossReferenceCodes.$each[updateIndex].crossReferenceCode.$touch();
  }

  addNewCrossReferenceCode() {
    this.addingNewCrossReferenceCode = !this.addingNewCrossReferenceCode;
    this.agency.crossReferenceCodes.push({
      $pending: true,
      $new: true,
      index: this.agency.crossReferenceCodes.length,
    });
  }

  saveNewCrossReferenceCode() {
    this.addingNewCrossReferenceCode = !this.addingNewCrossReferenceCode;
    const crossReferenceCode = this.agency.crossReferenceCodes[this.agency.crossReferenceCodes.length - 1];
    crossReferenceCode.$pending = false;

    this.newCrossReferenceCodes.push(crossReferenceCode);
  }

  clearNewCrossReferenceCode() {
    this.addingNewCrossReferenceCode = !this.addingNewCrossReferenceCode;
    this.agency.crossReferenceCodes.pop();
  }

  removeCrossReferenceCode(crossReferenceCode: any) {
    if (crossReferenceCode.$new) {
      this.newCrossReferenceCodes.splice(this.newCrossReferenceCodes.find((p) => p.index === crossReferenceCode.index), 1);
    } else {
      this.removedCrossReferenceCodes.push(crossReferenceCode);
    }
    this.agency.crossReferenceCodes.splice(crossReferenceCode.index, 1);
    this.agency.crossReferenceCodes.forEach((p, i) => {
      p.index = i;
    });
  }

  addNewScheduleEntry() {
    this.addingNewScheduleEntry = !this.addingNewScheduleEntry;
    this.agency.collectingSchedule.push({
      $pending: true,
      $new: true,
      agencyId: this.agency.agencyId,
      taxBillCheckIn: new Verified(undefined, false),
      dueDate: new Verified(null, false),
      lenderId: null,
      index: this.agency.collectingSchedule.length,
    });
  }

  saveNewScheduleEntry() {
    this.addingNewScheduleEntry = !this.addingNewScheduleEntry;
    const collectingScheduleEntry = this.agency.collectingSchedule[this.agency.collectingSchedule.length - 1];
    collectingScheduleEntry.$pending = false;

    this.newSchedules.push(collectingScheduleEntry);
  }

  clearNewScheduleEntry() {
    this.addingNewScheduleEntry = !this.addingNewScheduleEntry;
    this.agency.collectingSchedule.pop();
  }

  removeScheduleEntry(scheduleEntry: any) {
    if (scheduleEntry.$new) {
      this.newSchedules.splice(this.newSchedules.find((p) => p.index === scheduleEntry.index).index, 1);
    } else {
      this.removedSchedules.push(scheduleEntry);
    }
    this.agency.collectingSchedule.splice(scheduleEntry.index, 1);
    this.agency.collectingSchedule.forEach((p, i) => {
      p.index = i;
    });
  }

  lenderIsSelected(index: number) {
    this.agency.crossReferenceCodes.forEach((code) => {
      code.lenderName = this.lenders.find((l) => l.id === code.lenderNumber).name;
    });

    this.$v.agency.crossReferenceCodes.$each[index].lenderNumber.$touch();
  }

  calculateDisplayString(lender: Lender) {
    return `${lender.id} - ${lender.name}`;
  }

  addNewConfiguration() {
    this.addingNewConfiguration = !this.addingNewConfiguration;
    this.agency.parcelConfigurations.push({
      configuration: '',
      $pending: true,
      $new: true,
      index: this.agency.parcelConfigurations.length,
    })
  }

  saveNewConfiguration() {
    this.addingNewConfiguration = !this.addingNewConfiguration;
    const configuration = this.agency.parcelConfigurations[this.agency.parcelConfigurations.length - 1];
    configuration.$pending = false;

    this.newConfigurations.push(configuration);
  }

  clearNewConfiguration() {
    this.addingNewConfiguration = !this.addingNewConfiguration;
    this.agency.parcelConfigurations.pop();
  }

  removeConfiguration(configuration: any) {
    // can be removed once all local this.configurations stuff is removed.
    // Otherwise, the configuration object gets reindexed in the configurations.forEach, so we don't
    // have the index to splice out of agency.parcelConfigurations.
    const tempButImportantIndex = configuration.index;
    let spliceIndex = -1;
    let isNewConfiguration = false;

    this.newConfigurations.forEach((nc, i) => {
      if (nc.configuration === configuration.configuration) { // not guaranteed to be unique... but okay practically
        spliceIndex = i;
        isNewConfiguration = true;
      }
    });

    if (isNewConfiguration) {
      this.newConfigurations.splice(spliceIndex, 1);
    } else {
      this.removedConfigurations.push(configuration);
    }
    this.agency.parcelConfigurations.splice(tempButImportantIndex, 1);
    this.agency.parcelConfigurations.forEach((c, i) => {
      c.index = i;
    })
  }

  addNewRelatedTaxOffice() {
    this.addingNewRelatedTaxOffice = !this.addingNewRelatedTaxOffice;
    this.agency.relatedAgencies.push({
      $pending: true,
      $new: true,
      index: this.agency.relatedAgencies.length,
    });
  }

  saveNewRelatedTaxOffice() {
    if (this.selectedAgency && this.determineName(this.selectedAgency) === this.agencySearch) {
      // changing the chosen object so it displays well
      this.selectedAgency.county = this.selectedAgency.address.value.county;
      this.selectedAgency.state = this.selectedAgency.address.value.state;
      this.selectedAgency.agencyCode = this.selectedAgency.capAgency;
      this.selectedAgency.$pending = false;
      this.selectedAgency.$new = true;

      this.agency.relatedAgencies[this.agency.relatedAgencies.length - 1] = cloneDeep(this.selectedAgency);
      this.newRelatedAgencies.push(cloneDeep(this.selectedAgency));
      this.addingNewRelatedTaxOffice = !this.addingNewRelatedTaxOffice;
      this.agency.relatedAgencies.forEach((c, i) => {
        c.index = i;
      });
    }
  }

  clearNewRelatedTaxOffice() {
    this.addingNewRelatedTaxOffice = !this.addingNewRelatedTaxOffice;
    this.agency.relatedAgencies.pop();
  }

  removeRelatedTaxOffice(office: IRelatedAgency) {
    const removedAgency = this.agency.relatedAgencies.splice(office.index, 1)[0];
    this.removedRelatedAgencies.push(cloneDeep(removedAgency))
    this.agency.relatedAgencies.forEach((c, i) => {
      c.index = i;
    });
  }

  determineName(item: Agency): string {
    if (!item) {
      return '';
    }

    let nameString = item.name;

    if (item.address && item.address.value && item.address.value.county) {
      nameString += `, ${item.address.value.county}`;
    }

    if (item.address && item.address.value && item.address.value.state) {
      nameString += `, ${item.address.value.state}`;
    }

    nameString += `, ${item.capAgency}`;

    return nameString;
  }

  determineDTCOName(item: Agency): string {
    if (!item) {
      return '';
    }

    let nameString = item.name;

    if (item.county || item.state) {
      nameString += ' - '
      if (item.county) {
        nameString += `${item.county}${item.state ? ', ' : ''}`;
      }

      if (item.state) {
        nameString += `${item.state}`;
      }
    }

    nameString += ` - ${item.capAgency}`;

    return nameString;
  }

  confirmDeletion() {
    this.confirmingDeletion = true;
  }

  dismissDeleteAgencyDialog() {
    this.confirmingDeletion = false;
  }

  handleRollback() {
    this.showSuccess({
      text: 'Rollback successful. Please refresh the page to see changes.',
      actions: {
        text: 'Refresh',
        function: () => { document.location.reload() },
      },
    });
  }

  @Emit('deleted')
  async deleteAgency() {
    const result = await this.service.deleteAgency(this.$route.params.id);
    delete result.files;
    this.agency = { ...this.agency, ...result };
    this.confirmingDeletion = false;
    this.editMode = false;
    this.forceLeave = false;

    return this.agency;
  }

  sortCollectingScheduleEntries(items: ICollectingSchedule) {
    items.sort((a, b) => {
      if (a.lenderId && !b.lenderId) {
        return 1;
      }

      if (!a.lenderId && b.lenderId) {
        return -1;
      }

      return 0;
    });

    return items;
  }
}
